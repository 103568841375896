
import { Options, Vue } from 'vue-class-component'
import Language from '@/includes/language'
import Loading from '@/components/Loading.vue'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import ApiResource, { Meta } from '@/interfaces/api_resource'
import Pagination from '@/components/Pagination.vue'
import { computed } from 'vue'
import User from '@/interfaces/user'
import store from '@/store'
import Offer from '@/interfaces/offer'
import { useRoute } from 'vue-router'
import { readableDateTime, setTitle } from '@/includes/functions'

@Options({
  name: 'CustomerOffers',
  components: { Loading, Pagination }
})
export default class CustomerOffers extends Vue {
  readableDateTime = readableDateTime;
  language = Language;
  customerId = '';
  isLoading = false;
  totalOffers = 0;
  offers: Offer[] = []
  meta = {} as Meta;
  currentUser = {} as User;
  public async mounted (): Promise<void> {
    this.isLoading = false
    const user = computed<User | null>(() => store.state.user)
    if (user.value) {
      this.currentUser = user.value
    }
    const route = useRoute()
    const customerId = computed(() => route.params.customerId)
    if (customerId.value) {
      this.customerId = customerId.value.toString()
      setTitle(this.language.lang('customerOffers') + ` ${this.customerId}`)
      await this.loadOffers()
    }
  }

  public async loadOffers (page = 1): Promise<void> {
    this.isLoading = true
    try {
      const usersResponse = await BackendConnectionManager.get<ApiResource<Offer>>(`offers/customer/${this.customerId}?page=${page.toString()}`)
      this.offers = usersResponse.data.data
      this.meta = usersResponse.data.meta
      this.totalOffers = usersResponse.data.meta.total
    } finally {
      this.isLoading = false
    }
  }
}
