
import { Options, Vue } from 'vue-class-component'
import Loading from '@/components/Loading.vue'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import Router from '@/router'
@Options({
  name: 'Login',
  components: { Loading }
})
export default class Logout extends Vue {
  async mounted (): Promise<void> {
    try {
      await BackendConnectionManager.post('auth/logout', {})
      await BackendConnectionManager._logout()
    } catch (error) {
      await BackendConnectionManager._logout()
    } finally {
      await Router.push({ name: 'Login', query: { redirect: Router.currentRoute.value.query.redirect as string | undefined } })
    }
  }
}
