
import { Options, Vue } from 'vue-class-component'
import Loading from '@/components/Loading.vue'
import Languages from '@/includes/language'
import { computed, ComputedRef } from 'vue'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import User from '@/interfaces/user'
import Router from '@/router'
import Toast from '@/includes/toast'
import { setTitle } from '@/includes/functions'
@Options({
  name: 'NewEmployee',
  components: { Loading }
})
export default class NewEmployee extends Vue {
  language = Languages;
  isLoading = false;
  email = '';
  firstname = '';
  lastname = '';
  password = '';
  passwordConfirmation = '';
  phone = '';
  role = 'user';
  selectedLanguage = 'de';
  informUserByEmail = true;
  canSubmit: ComputedRef<boolean> = computed(() => false);
  public async mounted (): Promise<void> {
    setTitle(this.language.lang('employee'))
    this.isLoading = false
    this.canSubmit = computed(() => {
      return this.firstname.trim().length > 2 &&
        this.lastname.trim().length > 2 &&
        this.email.trim().length > 3 &&
        this.password.trim().length > 5 &&
        this.passwordConfirmation.trim().length > 5 &&
        this.passwordConfirmation.trim() === this.password.trim() &&
        ['en', 'de'].includes(this.selectedLanguage.trim()) &&
        ['user', 'owner'].includes(this.role.trim())
    })
  }

  public async createNewUser (): Promise<void> {
    this.isLoading = true
    try {
      const newUserResponse = await BackendConnectionManager.post<User>('users', {
        email: this.email,
        password: this.password,
        firstname: this.firstname,
        lastname: this.lastname,
        phone: this.phone,
        language: this.selectedLanguage,
        role: this.role,
        informUserByEmail: this.informUserByEmail
      })
      console.log(newUserResponse.data)
      Toast.success(this.language.lang('done'))
      await Router.push({ name: 'Employees' })
      return
    } catch (error: any) {
      // eslint-disable-next-line no-unused-expressions
      error.response?.data?.errors?.forEach((singleError: { message: string }) => {
        Toast.danger(singleError.message)
      })
    } finally {
      this.isLoading = false
    }
  }
}
