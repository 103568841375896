
import { Options, Vue } from 'vue-class-component'
import Language from '@/includes/language'
import Router from '@/router'

@Options({
  name: 'OpenContractByIdForm'
})
export default class OpenContractForm extends Vue {
  language = Language;
  contractId = 0;

  public async openContract (): Promise<void> {
    await Router.push({ name: 'ViewContract', params: { contractId: this.contractId } })
  }
}
