
import { Options, Vue } from 'vue-class-component'
import Loading from '@/components/Loading.vue'
import Language from '@/includes/language'
import User from '@/interfaces/user'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import ApiResource, { Meta } from '@/interfaces/api_resource'
import Pagination from '@/components/Pagination.vue'
import { readableDateTime, setTitle, timeUntilNow } from '@/includes/functions'
@Options({
  name: 'EmployeesList',
  components: { Loading, Pagination }
})
export default class EmployeesList extends Vue {
  readableDateTime = readableDateTime;
  language = Language;
  users: User[] = [];
  totalUsers = 0;
  isLoading = true;
  meta = {} as Meta;
  timeUntilNow = timeUntilNow;
  public async mounted (): Promise<void> {
    this.isLoading = false
    await this.loadUser()
  }

  public async loadUser (page = 1): Promise<void> {
    setTitle(this.language.lang('employees'))
    this.isLoading = true
    try {
      const usersResponse = await BackendConnectionManager.get<ApiResource<User>>('users?page=' + page)
      this.users = usersResponse.data.data
      this.meta = usersResponse.data.meta
      this.totalUsers = usersResponse.data.meta.total
    } finally {
      this.isLoading = false
    }
  }
}
