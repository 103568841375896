
import { Options, Vue } from 'vue-class-component'
import Language from '@/includes/language'
import Loading from '@/components/Loading.vue'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import { DatePicker } from 'v-calendar'
import User from '@/interfaces/user'
import { computed, watch } from 'vue'
import store from '@/store'
import Contract from '@/interfaces/contract'
import ApiResource, { Meta } from '@/interfaces/api_resource'
import Pagination from '@/components/Pagination.vue'
import { readableDateTime, setTitle } from '@/includes/functions'

@Options({
  name: 'SearchContracts',
  components: { Loading, DatePicker, Pagination }
})
export default class SearchContracts extends Vue {
  readableDateTime = readableDateTime;
  language = Language;
  contracts: Contract[] = [];
  totalContracts = 0;
  isLoading = true;
  meta = {} as Meta;
  userInfo = {} as User;
  inSearch = false
  blankTarget = true

  fromDate: null | string = '';
  toDate: null | string = '';
  status: 'all' | 'accepted' | 'open' = 'all';
  cleaning = false;
  trash = false;
  packagingPricingType: 'all' |'hourly' | 'flatRate' | 'maxPrice' = 'all';
  pricingType: 'all' |'hourly' | 'flatRate' | 'maxPrice' = 'all';
  createdBy = '';
  canSearch = computed<boolean>(() => false);

  public async mounted (): Promise<void> {
    this.isLoading = false
    setTitle(this.language.lang('searchContracts'))
    const user = computed<User | null>(() => store.state.user)
    if (user.value) {
      this.userInfo = user.value
    }
    const watchFromDate = computed<string | null>(() => this.fromDate)
    watch(watchFromDate, () => {
      this.toDate = ''
    })
    this.canSearch = computed<boolean>(() => {
      return !this.isNoSearchParameter()
    })
  }

  public async searchContract (page = 1): Promise<void> {
    this.isLoading = true
    let fromDate: Date | string = ''
    let toDate: Date | string = ''
    if (this.fromDate) {
      fromDate = new Date(this.fromDate)
      fromDate.setHours(0, 0, 0, 0)
    }
    if (this.toDate) {
      toDate = new Date(this.toDate)
      toDate.setHours(23, 59, 59, 999)
    }
    try {
      const usersResponse = await BackendConnectionManager.post<ApiResource<Contract>>(`search/contracts?page=${page}`, {
        fromDate: fromDate,
        toDate: toDate,
        status: this.status,
        priceType: this.pricingType,
        packagingPriceType: this.packagingPricingType,
        trash: this.trash,
        cleaning: this.cleaning
      })
      this.contracts = usersResponse.data.data
      this.meta = usersResponse.data.meta
      this.totalContracts = usersResponse.data.meta.total
      this.inSearch = this.contracts.length > 0
    } finally {
      this.isLoading = false
    }
  }

  public async reset (): Promise<void> {
    this.fromDate = ''
    this.toDate = ''
    this.status = 'all'
    this.pricingType = 'all'
    this.packagingPricingType = 'all'
    this.cleaning = false
    this.trash = false
    this.inSearch = false
    this.contracts = []
    this.totalContracts = 0
    this.meta = {} as Meta
  }

  public isNoSearchParameter (): boolean {
    return (this.fromDate === null || this.fromDate === '') &&
      (this.toDate === null || this.toDate?.trim() === '') &&
    this.status === 'all' &&
    this.pricingType === 'all' && !this.cleaning && !this.trash
  }
}
