import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dynamic = _resolveComponent("Dynamic")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.template === 'dynamic')
      ? (_openBlock(), _createBlock(_component_Dynamic, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}