
import { Options, Vue } from 'vue-class-component'
import { computed } from 'vue'
import store from '@/store'
import Router from '@/router'
import Language from '@/includes/language'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import Loading from '@/components/Loading.vue'
import { Languages } from '@/includes/types'
import { LoginResponse } from '@/interfaces/responses'
import Toast from '@/includes/toast'
import { setTitle } from '@/includes/functions'

@Options({
  name: 'Login',
  components: { Loading }
})
export default class Login extends Vue {
  public language = Language;
  public email = '';
  public password = '';
  public isLoading = false;
  public async mounted (): Promise<void> {
    const isLogin = computed(() => store.state.isLogin)
    setTitle(this.language.lang('login'))
    if (isLogin.value) {
      await Router.push({ name: 'Home' })
    }
  }

  public async submitForm (): Promise<void> {
    this.isLoading = true
    try {
      const response = await BackendConnectionManager.post<LoginResponse>(
        'auth/login',
        {
          email: this.email,
          password: this.password
        }
      )
      await store.dispatch('setUser', response.data.user)
      let appLanguage = Languages.English
      if (response.data.user.language === 'de') {
        appLanguage = Languages.Germany
      }
      this.language.changeLanguage(appLanguage)
      await store.dispatch('setAccessToken', response.data.token.token)
      const isThereRedirect = Router.currentRoute.value.query.redirect as string | undefined
      if (isThereRedirect) {
        await Router.push({ path: isThereRedirect })
      } else {
        await Router.push({ name: 'Home' })
      }
      Toast.success(this.language.lang('successfulLogin'))
    } catch (error: any) {
      // eslint-disable-next-line no-unused-expressions
      error.response?.data?.errors?.forEach((singleError: {message: string}) => {
        Toast.danger(singleError.message)
      })
    } finally {
      this.isLoading = false
    }
  }
}
