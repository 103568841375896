
import { Options, Vue } from 'vue-class-component'
import Loading from '@/components/Loading.vue'
import Language from '@/includes/language'
import { computed } from 'vue'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import { setTitle } from '@/includes/functions'

@Options({
  name: 'New Contract Template',
  components: { Loading }
})
export default class NewContractTemplate extends Vue {
  language = Language;
  isLoading = false;
  title = '';
  templateLanguage = 'de';
  templateFile: File | null = null;
  canCreateNewTemplate = computed(() => false);

  public async mounted (): Promise<void> {
    this.isLoading = false
    this.canCreateNewTemplate = computed(() => this.title.trim().length > 3 && this.templateFile !== null)
    setTitle(this.language.lang('newContractTemplate'))
  }

  public async updateFile (event: Event): Promise<void> {
    const files = (event.target as HTMLInputElement).files
    if (files && files.length > 0) {
      this.templateFile = files[0]
    }
  }

  public async uploadTemplate (): Promise<void> {
    this.isLoading = true
    try {
      const formData = new FormData()
      formData.append('title', this.title)
      formData.append('language', this.templateLanguage)
      formData.append('template', (this.templateFile as unknown as string))
      await BackendConnectionManager.post('contract-templates', formData)
    } catch (e: any) {
      console.log(e.response)
    } finally {
      this.isLoading = false
    }
  }
}
