export default class PdfViewApi {
  pdfjsLib: any = (window as { [key: string]: any })['pdfjs-dist/build/pdf']
  pdfDoc: any = null;
  scale = 1; // Set Scale for zooming PDF.
  resolution = 2; // Set Resolution to Adjust PDF clarity.
  constructor () {
    this.pdfjsLib.GlobalWorkerOptions.workerSrc = '/worker.min.js'
  }

  public loadPdfFromUrl (url: string): Promise<boolean> {
    // Read PDF from URL.
    return new Promise((resolve, reject) => {
      this.pdfjsLib.getDocument(url).promise.then((pdfDoc_: any) => {
        this.pdfDoc = pdfDoc_

        // Reference the Container DIV.
        const pdfContainer = document.getElementById('pdf_container') as HTMLElement
        pdfContainer.style.display = 'block'

        // Loop and render all pages.
        for (let i = 1; i <= this.pdfDoc.numPages; i++) {
          this.renderPage(pdfContainer, i)
        }
        resolve(true)
      }).catch((error: any) => {
        reject(error)
      })
    })
  }

  public renderPage (pdfContainer: HTMLElement, num: number): void {
    this.pdfDoc.getPage(num).then((page: any) => {
      // Create Canvas element and append to the Container DIV.
      const canvas = document.createElement('canvas')
      canvas.id = 'pdf-' + num
      const ctx = canvas.getContext('2d')
      pdfContainer.appendChild(canvas)

      // Create and add empty DIV to add SPACE between pages.
      const spacer = document.createElement('div')
      spacer.style.height = '20px'
      const hr = document.createElement('hr')
      hr.style.border = 'none'
      hr.style.height = '2px'
      hr.style.color = '#333'
      hr.style.backgroundColor = '#333'
      pdfContainer.appendChild(hr)
      pdfContainer.appendChild(spacer)

      // Set the Canvas dimensions using ViewPort and Scale.
      const viewport = page.getViewport({ scale: this.scale })
      canvas.height = this.resolution * viewport.height
      canvas.width = this.resolution * viewport.width

      // Render the PDF page.
      const renderContext = {
        canvasContext: ctx,
        viewport: viewport,
        transform: [this.resolution, 0, 0, this.resolution, 0, 0],
        textLayerMode: 1
      }

      page.render(renderContext)
    })
  }
}
