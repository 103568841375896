
import { Options, Vue } from 'vue-class-component'
import Loading from '@/components/Loading.vue'
import User from '@/interfaces/user'
import { Ref, computed, ref, watch } from 'vue'
import store from '@/store'
import Language from '@/includes/language'
import { DatePicker } from 'v-calendar'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import Contract from '@/interfaces/contract'
import Appointment from '@/interfaces/appointment'
import { setTitle } from '@/includes/functions'
import MonthlyChart from '@/components/Charts/Monthly.vue'

@Options({
  components: { Loading, DatePicker, MonthlyChart }
})
export default class Home extends Vue {
  userInfo = {} as User;
  isLoading = true;
  language = Language;
  calendarDate = new Date();
  chartsData: Ref<{
    date: { year: number, month: number },
    data: { contracts: Contract[], appointments: Appointment[] }
  }> = ref({
    data: {
      contracts: [],
      appointments: []
    },
    date: { year: 2023, month: 5 }
  });

  calendarAttributes: any[] = [
    {
      monthKey: 'all',
      key: 'today',
      bar: '',
      highlight: 'red',
      dates: [new Date()],
      contracts: [] as Contract[],
      appointments: [] as Appointment[]
    }
  ];

  showContracts: Contract[] = [];
  showAppointments: Appointment[] = [];

  monthsContracts: { [key: string]: Contract[]; } = {};
  monthsAppointments: { [key: string]: Appointment[]; } = {};

  public async mounted (): Promise<void> {
    const user = computed<User | null>(() => store.state.user)
    if (user.value) {
      this.userInfo = user.value
    }
    this.isLoading = false
    setTitle(this.language.lang('dashboard'))
    const calendarComputedDate = computed<Date>(() => this.calendarDate)
    watch<Date>(calendarComputedDate, async (newDate: Date | null) => {
      if (newDate === null) {
        this.calendarDate = new Date()
        return
      }
      const year = newDate.getFullYear()
      const month = newDate.getMonth() + 1
      const day = newDate.getDate()
      if (typeof this.monthsContracts[`${year}-${month}`] !== 'object' || typeof this.monthsAppointments[`${year}-${month}`] !== 'object') {
        await this.loadThisMonthContracts()
      }
      this.showContracts = this.monthsContracts[`${year}-${month}`].filter(c => {
        return (new Date(c.start_date_time)).toISOString().split('T')[0] === (new Date(year, (month - 1), (day + 1))).toISOString().split('T')[0]
      })
      this.showAppointments = this.monthsAppointments[`${year}-${month}`].filter(c => {
        return (new Date(c.date_time)).toISOString().split('T')[0] === (new Date(year, (month - 1), (day + 1))).toISOString().split('T')[0]
      })
    })
    await this.loadThisMonthContracts()
    const currentDate = new Date()
    this.showContracts = this.monthsContracts[`${currentDate.getFullYear()}-${currentDate.getMonth() + 1}`]
    this.showAppointments = this.monthsAppointments[`${currentDate.getFullYear()}-${currentDate.getMonth() + 1}`]
  }

  public updateChartData (year: number, month: number, data: { contracts: Contract[], appointments: Appointment[] }): void {
    this.chartsData.value = {
      date: { month, year },
      data: data
    }
  }

  public async loadThisMonthContracts ({ yearArg = 0, monthArg = 0 } = {}): Promise<void> {
    const isThisDateLoaded = typeof this.monthsContracts[`${yearArg}-${monthArg}`] === 'object'
    if (isThisDateLoaded) {
      return
    }
    this.isLoading = true
    try {
      const year = yearArg > 0 ? yearArg : this.calendarDate.getFullYear()
      const month = monthArg > 0 ? monthArg : this.calendarDate.getMonth() + 1
      const thisMonthContractsAndAppointments = await BackendConnectionManager.get<{
        contracts: Contract[], appointments: Appointment[]
      }>(`contracts/month-contracts/${year}/${month}`)
      this.monthsContracts[`${year}-${month}`] = thisMonthContractsAndAppointments.data.contracts
      this.monthsAppointments[`${year}-${month}`] = thisMonthContractsAndAppointments.data.appointments
      this.updateChartData(yearArg, monthArg, {
        contracts: this.monthsContracts[`${yearArg}-${monthArg}`],
        appointments: this.monthsAppointments[`${yearArg}-${monthArg}`]
      })
      const thisMonthAtt = this.calendarAttributes.find(at => at.monthKey === `${yearArg}-${monthArg}`)
      if (!thisMonthAtt) {
        this.calendarAttributes.push({
          monthKey: `${year}-${month}`,
          bar: 'red',
          dates: thisMonthContractsAndAppointments.data.contracts.map(c => new Date(c.start_date_time)),
          contracts: thisMonthContractsAndAppointments.data.contracts
        })
        this.calendarAttributes.push({
          monthKey: `${year}-${month}`,
          bar: 'blue',
          dates: thisMonthContractsAndAppointments.data.appointments.map(c => new Date(c.date_time)),
          appointments: thisMonthContractsAndAppointments.data.appointments
        })
      }
    } finally {
      this.isLoading = false
    }
  }

  public async loadAnotherPageContract (pageDate: {year: number, month: number}): Promise<void> {
    if (typeof this.monthsContracts[`${pageDate.year}-${pageDate.month}`] !== 'object') {
      await this.loadThisMonthContracts({
        yearArg: pageDate.year, monthArg: pageDate.month
      })
    } else {
      this.updateChartData(pageDate.year, pageDate.month, {
        contracts: this.monthsContracts[`${pageDate.year}-${pageDate.month}`],
        appointments: this.monthsAppointments[`${pageDate.year}-${pageDate.month}`]
      })
    }
  }
}
