
import { Options, Vue } from 'vue-class-component'
import EditContractDynamic from '@/components/edit_contract_template/edit_contract_dynamic.vue'
import { setTitle } from '@/includes/functions'
import Language from '@/includes/language'
@Options({
  name: 'EditContract',
  components: { EditContractDynamic }
})
export default class EditContract extends Vue {
  language = Language;
  template: 'dynamic' | 'standard' = 'dynamic';
  public async mounted (): Promise<void> {
    this.template = 'dynamic'
    setTitle(this.language.lang('editContract'))
  }
}
