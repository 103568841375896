
import { Options, Vue } from 'vue-class-component'
import Language from '@/includes/language'
import Loading from '@/components/Loading.vue'
import { computed } from 'vue'
import User from '@/interfaces/user'
import store from '@/store'
import Contract from '@/interfaces/contract'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import Toast from '@/includes/toast'
import Router from '@/router'
import { readableDateTime, setTitle } from '@/includes/functions'
@Options({
  name: 'DeleteAcceptedContract',
  components: { Loading }
})
export default class DeleteAcceptedContract extends Vue {
  readableDateTime = readableDateTime;
  language = Language;
  isLoading = true;
  userInfo = {} as User;
  contractId = '';
  contractInfo = {} as Contract;
  public async mounted (): Promise<void> {
    this.isLoading = true
    setTitle(this.language.lang('deleteAcceptedContract'))
    const currentUser = computed<User | null>(() => store.state.user)
    if (currentUser.value) {
      this.userInfo = currentUser.value
    }
    this.isLoading = false
  }

  public async loadContractInfo (contractId: string): Promise<void> {
    this.isLoading = true
    this.contractInfo = {} as Contract
    try {
      const contractResponse = await BackendConnectionManager.get<Contract>(`contracts/${contractId}`)
      this.contractInfo = contractResponse.data
    } catch (e) {
      if (e.response.status === 404) {
        Toast.danger(this.language.lang('noContracts'))
        return
      }
      Toast.danger('Unknown Error')
    } finally {
      this.isLoading = false
    }
  }

  public async deleteContract (): Promise<void> {
    this.isLoading = true
    try {
      await BackendConnectionManager.delete(`contracts/${this.contractInfo.id}?accepted=1`, {})
      await Router.push({ name: 'Contracts' })
    } finally {
      this.isLoading = false
    }
  }
}
