
import { Options, Vue } from 'vue-class-component'
import Loading from '@/components/Loading.vue'
import Language from '@/includes/language'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import ApiResource, { Meta } from '@/interfaces/api_resource'
import Pagination from '@/components/Pagination.vue'
import { readableDateTime, setTitle, timeUntilNow } from '@/includes/functions'
import Contract from '@/interfaces/contract'
import OpenContractForm from '@/components/Contracts/OpenContractForm.vue'
import { computed } from 'vue'
import User from '@/interfaces/user'
import store from '@/store'
import { useRoute } from 'vue-router'
@Options({
  name: 'ContractsList',
  components: { Loading, Pagination, OpenContractForm }
})
export default class ContractsList extends Vue {
  readableDateTime = readableDateTime;
  customerId: string | null = null;
  language = Language;
  contracts: Contract[] = [];
  totalContracts = 0;
  isLoading = true;
  meta = {} as Meta;
  currentUser = {} as User;
  timeUntilNow = timeUntilNow;
  public async mounted (): Promise<void> {
    this.isLoading = false
    const route = useRoute()
    this.customerId = (route.query.customer as string) || null
    const currentUser = computed<User | null>(() => store.state.user)
    if (currentUser.value) {
      this.currentUser = currentUser.value
    }
    await this.loadContracts()
    setTitle(this.language.lang('contracts'))
  }

  public async loadContracts (page = 1): Promise<void> {
    this.isLoading = true
    try {
      const usersResponse = await BackendConnectionManager.get<ApiResource<Contract>>('contracts?page=' + page + `${this.customerId ? '&customer=' + this.customerId : ''}`)
      this.contracts = usersResponse.data.data
      this.meta = usersResponse.data.meta
      this.totalContracts = usersResponse.data.meta.total
    } finally {
      this.isLoading = false
    }
  }
}
