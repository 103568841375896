
import { Options, Vue } from 'vue-class-component'
import Loading from '@/components/Loading.vue'
import Languages from '@/includes/language'
import { computed, ComputedRef } from 'vue'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import Router from '@/router'
import Toast from '@/includes/toast'
import Customer from '@/interfaces/customer'
import { setTitle } from '@/includes/functions'
@Options({
  name: 'NewCustomer',
  components: { Loading }
})
export default class NewCustomer extends Vue {
  language = Languages;
  isLoading = false;
  email = '';
  firstname = '';
  lastname = '';
  phone = '';
  gender = 'male';
  selectedLanguage = 'de';
  customerComingFrom = ''
  country = '';
  city = '';
  zipCode = '';
  companyName = '';
  street = '';
  streetNumber = '';
  comingFromAutoComplete: string[] = [];
  canSubmit: ComputedRef<boolean> = computed(() => false);
  public async mounted (): Promise<void> {
    setTitle(this.language.lang('newCustomer'))
    this.isLoading = false
    this.canSubmit = computed(() => {
      return this.firstname.trim().length > 2 &&
        this.lastname.trim().length > 2 &&
        this.city.trim().length > 2 &&
        this.zipCode.trim().length > 1 &&
        this.street.trim().length > 2 &&
        this.streetNumber.trim().length > 0 &&
        this.customerComingFrom.trim().length > 0 &&
        this.email.trim().length > 5 &&
        ['male', 'female'].includes(this.gender.trim()) &&
        this.email.trim().length > 3 &&
        ['en', 'de', 'it', 'fr'].includes(this.selectedLanguage.trim())
    })
    await this.autoCompleteCustomerComingFrom()
  }

  public async createNewCustomer (): Promise<void> {
    this.isLoading = true
    try {
      const newUserResponse = await BackendConnectionManager.post<Customer>('customers', {
        email: this.email,
        firstname: this.firstname,
        lastname: this.lastname,
        phone: this.phone,
        language: this.selectedLanguage,
        comingFrom: this.customerComingFrom,
        gender: this.gender,
        country: this.country,
        city: this.city,
        zipCode: this.zipCode,
        street: this.street,
        streetNumber: this.streetNumber,
        companyName: this.companyName
      })
      console.log(newUserResponse.data)
      Toast.success(this.language.lang('done'))
      await Router.push({ name: 'Customers' })
      return
    } catch (error: any) {
      // eslint-disable-next-line no-unused-expressions
      error.response?.data?.errors?.forEach((singleError: { message: string }) => {
        Toast.danger(singleError.message)
      })
    } finally {
      this.isLoading = false
    }
  }

  public async autoCompleteCustomerComingFrom (): Promise<void> {
    this.isLoading = true
    try {
      const autoCompleteResponse = await BackendConnectionManager.get<string[]>('auto-complete/customer-coming-from')
      this.comingFromAutoComplete = autoCompleteResponse.data
    } finally {
      this.isLoading = false
    }
  }
}
