
import { Options, Vue } from 'vue-class-component'
import Loading from '@/components/Loading.vue'
import Language from '@/includes/language'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { setTitle } from '@/includes/functions'
import PdfViewApi from '@/includes/PdfViewApi'
import Offer from '@/interfaces/offer'
import Toast from '@/includes/toast'
@Options({
  name: 'ViewContract',
  components: { Loading }
})
export default class ViewContract extends Vue {
  isLoading = true;
  isThereContract = true;
  language = Language;
  offerInfo = {} as Offer;
  downloadUrl = '';
  showSuccess = false;
  showError = false;
  contractId = '';
  offerId = '';
  offerToken = '';

  public async mounted (): Promise<void> {
    this.isLoading = false
    const route = useRoute()
    const contractId = computed(() => route.params.contractId)
    const offerId = computed(() => route.params.offerId)
    const offerToken = computed(() => route.params.offerToken)
    if (contractId.value && offerId.value && offerToken.value) {
      await this.loadContractInfo(contractId.value.toString(), offerId.value.toString(), offerToken.value.toString())
      this.contractId = contractId.value.toString()
      this.offerId = offerId.value.toString()
      this.offerToken = offerToken.value.toString()
      setTitle(this.language.lang('contract') + ` ${this.offerInfo?.contract?.company_contract_id}`)
    }
  }

  public async downloadPdf (): Promise<void> {
    this.isLoading = true
    try {
      await BackendConnectionManager.download('../' + this.downloadUrl, '')
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }

  public async loadContractInfo (contractId: string, offerId: string, offerToken: string, noPdf = true): Promise<void> {
    this.isLoading = true
    try {
      const backendUrl = `/api/${BackendConnectionManager.apiVersion}`
      const requestUrl = `offers/load-offer/?no-pdf=${noPdf}&contractId=${contractId}&offerId=${offerId}&offerToken=${offerToken}`
      const pdfUrl = `${backendUrl}/${requestUrl}`
      this.downloadUrl = pdfUrl.slice(1)
      if (!noPdf) {
        this.renderPdf(pdfUrl)
        return
      }
      const offerInfo = await BackendConnectionManager.get<Offer>(requestUrl)
      this.offerInfo = offerInfo.data
      if (this.offerInfo.status === 'pending') {
        this.loadContractInfo(contractId, offerId, offerToken, false)
      }
    } catch (error: any) {
      console.log(error)
      // eslint-disable-next-line no-unused-expressions
      error.response?.data?.errors?.forEach((singleError: { message: string }) => {
        Toast.danger(singleError.message)
      })
    } finally {
      this.isLoading = false
    }
  }

  public async renderPdf (url: string): Promise<void> {
    const pdfViewApi = new PdfViewApi()
    this.isLoading = true
    try {
      await pdfViewApi.loadPdfFromUrl(url)
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }

  public async updateContractOfferStatus (status: 'accepted' | 'reject'): Promise<void> {
    this.isLoading = true
    try {
      await BackendConnectionManager.post('offers/update-offer/', {
        contractId: this.contractId,
        offerId: this.offerId,
        offerToken: this.offerToken,
        status: status
      })
      this.showError = false
      this.showSuccess = false
      if (status === 'reject') {
        this.showError = true
        this.offerInfo.status = 'reject'
        return
      }
      this.showSuccess = true
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }
}
