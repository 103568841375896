
import { Options, Vue } from 'vue-class-component'
import Loading from '@/components/Loading.vue'
import Language from '@/includes/language'
import Contract from '@/interfaces/contract'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import Address from '@/interfaces/address'
import User from '@/interfaces/user'
import { parseExpectedTime, readableDateTime, setTitle } from '@/includes/functions'
import Router from '@/router'
import store from '@/store'
import { UserRoles } from '@/includes/types'
import MailTemplate from '@/interfaces/mail_template'
import Toast from '@/includes/toast'
import NoteParser from '@/components/Contracts/NoteParser.vue'
import DeleteModal from '@/components/DeleteModal.vue'
import ViewContractItems from '@/components/ViewContractItems.vue'

@Options({
  name: 'ViewContract',
  components: { Loading, NoteParser, DeleteModal, ViewContractItems }
})
export default class ViewContract extends Vue {
  readableDateTime = readableDateTime;
  isLoading = true;
  language = Language;
  selectTemplate = ''
  mailTemplates: MailTemplate[] = [];
  contractInfo = {} as Contract;
  fromAddresses: Address[] = [];
  toAddresses: Address[] = [];
  creatorInfo = {} as User;

  sendCopyToCompanyEmail = false;
  showDeleteButton = false

  expectedTimeParsed = {
    from: 0,
    to: 0
  }

  public async mounted (): Promise<void> {
    this.isLoading = false
    const currentUser = computed<User | null>(() => store.state.user)
    if (currentUser.value) {
      if (currentUser.value?.role === UserRoles.Owner) {
        this.showDeleteButton = true
      }
    }
    const route = useRoute()
    const contractId = computed(() => route.params.contractId)
    if (contractId.value) {
      await this.loadContractInfo(contractId.value.toString())
      await this.loadMailTemplates()
      setTitle(this.language.lang('contract') + ` ${this.contractInfo.company_contract_id}`)
    }
  }

  public async loadMailTemplates (): Promise<void> {
    this.isLoading = true
    try {
      const usersResponse = await BackendConnectionManager.get<MailTemplate[]>('mail-templates?page=all')
      this.mailTemplates = usersResponse.data
      if (this.mailTemplates.length > 0) {
        this.selectTemplate = this.mailTemplates[0].uuid
      }
    } finally {
      this.isLoading = false
    }
  }

  public async loadContractInfo (contractId: string): Promise<void> {
    this.isLoading = true
    try {
      const contractResponse = await BackendConnectionManager.get<Contract>('contracts/' + contractId)
      this.contractInfo = contractResponse.data
      this.fromAddresses = this.contractInfo.addresses?.filter(ad => ad.direction === 'from') || []
      this.toAddresses = this.contractInfo.addresses?.filter(ad => ad.direction === 'to') || []
      this.creatorInfo = this.contractInfo.creator || {} as User
      const relocationParsedTime = parseExpectedTime(this.contractInfo.expected_time)
      this.expectedTimeParsed.from = relocationParsedTime[0] || 0
      this.expectedTimeParsed.to = relocationParsedTime[1] || 0
    } catch (e: any) {
      if (e.response?.status === 404) {
        Toast.danger(this.language.lang('noContractWithId'))
        await Router.push({ name: 'Contracts' })
      }
    } finally {
      this.isLoading = false
    }
  }

  public async deleteContract (): Promise<void> {
    this.isLoading = true
    try {
      await BackendConnectionManager.delete('contracts/' + this.contractInfo.company_contract_id, {})
      await Router.push({ name: 'Contracts' })
    } finally {
      this.isLoading = false
    }
  }

  public async openContract (): Promise<void> {
    this.isLoading = true
    try {
      const contractTitle = `${this.contractInfo.customer?.firstname} ${this.contractInfo.customer?.lastname} | ${this.contractInfo.company_contract_id}`
      await BackendConnectionManager.openInNewTab(`v1/contracts/${this.contractInfo.id}/view-html`, contractTitle)
    } finally {
      this.isLoading = false
    }
  }

  public async openReceipt (): Promise<void> {
    this.isLoading = true
    try {
      const contractTitle = `${this.contractInfo.customer?.firstname} ${this.contractInfo.customer?.lastname} | ${this.contractInfo.company_contract_id}`
      await BackendConnectionManager.openInNewTab(`v1/contracts/${this.contractInfo.id}/view-receipt`, contractTitle)
    } finally {
      this.isLoading = false
    }
  }

  public async openCleaningReceipt (): Promise<void> {
    this.isLoading = true
    try {
      const contractTitle = `${this.contractInfo.customer?.firstname} ${this.contractInfo.customer?.lastname} | ${this.contractInfo.company_contract_id}`
      await BackendConnectionManager.openInNewTab(`v1/contracts/${this.contractInfo.id}/view-cleaning-receipt`, contractTitle)
    } finally {
      this.isLoading = false
    }
  }

  public async makeOffer (): Promise<void> {
    this.isLoading = true
    try {
      await BackendConnectionManager.post('offers', {
        copyToCompany: this.sendCopyToCompanyEmail,
        mailTemplateId: this.selectTemplate,
        contractId: this.contractInfo.uuid
      })
      Toast.success(this.language.lang('offerCreatedSuccess'))
    } finally {
      this.isLoading = false
    }
  }
}
