
import { Options, Vue } from 'vue-class-component'
import Loading from '@/components/Loading.vue'
import Language from '@/includes/language'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import ApiResource, { Meta } from '@/interfaces/api_resource'
import Pagination from '@/components/Pagination.vue'
import { readableDateTime, setTitle, timeUntilNow } from '@/includes/functions'
import Customer from '@/interfaces/customer'
@Options({
  name: 'SearchCustomer',
  components: { Loading, Pagination }
})
export default class SearchCustomer extends Vue {
  readableDateTime = readableDateTime;
  language = Language;
  customers: Customer[] = [];
  totalCustomers = 0;
  isLoading = true;
  searchCustomer = '';
  meta = {} as Meta;
  searchExecution = 0;
  showSmallLoading = false;
  timeUntilNow = timeUntilNow;
  public async mounted (): Promise<void> {
    this.isLoading = false
    setTitle(this.language.lang('searchCustomers'))
  }

  public async searchCustomers (page = 1): Promise<void> {
    if (this.showSmallLoading) {
      return
    }
    clearTimeout(this.searchExecution)
    this.searchExecution = window.setTimeout(async () => {
      if (this.searchCustomer.trim().length === 0) {
        return
      }
      this.showSmallLoading = true
      this.isLoading = true
      try {
        const customersResponse = await BackendConnectionManager.post<
          ApiResource<Customer>
        >('customers/search?page=' + page, {
          searchWord: this.searchCustomer.trim()
        })
        this.customers = customersResponse.data.data
        this.meta = customersResponse.data.meta
        this.totalCustomers = this.meta.total
      } finally {
        this.showSmallLoading = false
        this.isLoading = false
      }
    }, 1000)
  }
}
