
import { Options, Vue } from 'vue-class-component'
import Language from '@/includes/language'
import Loading from '@/components/Loading.vue'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import Company from '@/interfaces/company'
import { computed } from 'vue'
import User from '@/interfaces/user'
import store from '@/store'
import { Cropper, Coordinates } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import jquery from '@/includes/jquery'
import { readableDateTime, setTitle } from '@/includes/functions'
@Options({
  name: 'ViewCompany',
  components: { Loading, Cropper }
})
export default class ViewCompany extends Vue {
  readableDateTime = readableDateTime;
  language = Language;
  isLoading = true;
  companyInfo = {} as Company;
  user = {} as User;
  showCropper = false;
  finalImageToSubmit = '';
  imageUrl = '';
  originalFileName = '';
  public async mounted (): Promise<void> {
    this.isLoading = false
    const user = computed<User | null>(() => store.state.user)
    if (user.value) {
      this.user = user.value
      await this.loadCompany()
      setTitle(this.language.lang('company') + `${this.companyInfo.name}`)
    }
  }

  public async changeLogo ({ canvas }: { coordinates: Coordinates; canvas: HTMLCanvasElement; }): Promise<void> {
    this.isLoading = true
    this.isLoading = false
    this.finalImageToSubmit = canvas.toDataURL('image/png')
  }

  public async renderImage (): Promise<void> {
    this.isLoading = true
    try {
      const logoInput = document.getElementById('logo') as HTMLInputElement
      if (!logoInput || !logoInput.value) {
        this.isLoading = false
        return
      }
      const formData = new FormData(document.getElementById('logoForm') as HTMLFormElement)
      const newImageData = formData.get('logo') as File
      if (newImageData) {
        this.originalFileName = newImageData.name
        const reader = new FileReader()
        reader.addEventListener(
          'load',
          () => {
            // convert image file to base64 string
            this.imageUrl = reader.result?.toString() || ''
          },
          false
        )
        reader.readAsDataURL(newImageData)
        this.showCropper = true
      }
    } finally {
      this.isLoading = false
    }
  }

  public async loadCompany (): Promise<void> {
    if (!this.user.company?.uuid) {
      return
    }
    this.isLoading = true
    try {
      const companyResponse = await BackendConnectionManager.get<Company>('companies/' + this.user.company.uuid)
      this.companyInfo = companyResponse.data
    } finally {
      this.isLoading = false
    }
  }

  public async updateCompanyLogo (): Promise<void> {
    this.isLoading = true
    try {
      const formData = new FormData()
      formData.append(
        'logo',
        ViewCompany.dataURLtoFile(
          this.finalImageToSubmit,
          this.originalFileName
        ) as unknown as string
      )
      await BackendConnectionManager.put<Company>(
        'companies/' + this.companyInfo.uuid + '/update-logo',
        formData,
        { 'Content-Type': 'multipart/form-data' }
      )
      jquery('#exampleModal').modal('hide')
      location.reload()
    } finally {
      this.isLoading = false
    }
  }

  private static dataURLtoFile (dataUrl: string, filename: string) {
    const arr = dataUrl.split(',')
    if (arr.length > 0 && arr[0].trim() !== '') {
      const mime = arr[0].match(/:(.*?);/)
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime?.[1] })
    }
  }
}
