import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-md-8" }
const _hoisted_2 = {
  for: "contractId",
  class: "visually-hidden"
}
const _hoisted_3 = { class: "col-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", {
    class: "row g-3",
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openContract && _ctx.openContract(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.language.lang('contractId')), 1),
      _withDirectives(_createElementVNode("input", {
        type: "number",
        min: "0",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.contractId) = $event)),
        class: "form-control",
        id: "contractId",
        placeholder: "2022"
      }, null, 512), [
        [_vModelText, _ctx.contractId]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        type: "submit",
        class: _normalizeClass([{'disabled': _ctx.contractId <= 0}, "btn btn-outline-primary mb-3"])
      }, _toDisplayString(_ctx.language.lang('openContract')), 3)
    ])
  ], 32))
}