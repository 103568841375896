
import { Options, Vue } from 'vue-class-component'
import Language from '@/includes/language'
import Loading from '@/components/Loading.vue'
import Company from '@/interfaces/company'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import User from '@/interfaces/user'
import { computed, ComputedRef, watch } from 'vue'
import store from '@/store'
import Toast from '@/includes/toast'
import Router from '@/router'
import { UserRoles } from '@/includes/types'
import { setTitle, updateUserFromWhoAmiI } from '@/includes/functions'

@Options({
  name: 'EditCompany',
  components: { Loading }
})
export default class EditCompany extends Vue {
  language = Language;
  isLoading = true;
  companyInfo = {} as Company;
  userInfo = {} as User;
  canSubmit: ComputedRef<boolean> = computed(() => false);
  currentTab = 'settings'
  agbFileUrl = ''

  // company new information
  companyName = '';
  email = '';
  phoneNumber = '';
  contractHeader = '';
  contractFooter = '';
  receiptNote = '';
  website = '';
  backgroundColor = '';
  textColor = '';
  city = '';
  zipCode = '';
  street = '';
  streetNumber = '';
  public async mounted (): Promise<void> {
    this.canSubmit = computed(() => {
      return this.companyName?.trim().length > 2 &&
        this.email?.trim().length > 8 &&
        this.phoneNumber?.trim().length > 5 &&
        this.city?.trim().length > 2 &&
        this.zipCode?.trim().length > 1 &&
        this.street?.trim().length > 2 &&
        this.streetNumber?.trim().length > 0
    })
    const tab = computed<string>(() => this.currentTab)
    watch(tab, async (newValue: string) => {
      if (newValue === 'agb' && this.agbFileUrl?.trim().length === 0) {
        await this.loadAgb()
      }
    })
    const user = computed<User | null>(() => store.state.user)
    if (user.value) {
      if (user.value?.role !== UserRoles.Owner) {
        await Router.push({ name: 'ViewCompany' })
        return
      }
      this.userInfo = user.value
      await this.loadCompanyInfo()
      setTitle(this.language.lang('company') + ` ${this.companyInfo.name}`)
    }
  }

  public async loadCompanyInfo (): Promise<void> {
    this.isLoading = true
    try {
      const companyInfoResponse = await BackendConnectionManager.get<Company>('companies/' + this.userInfo.company?.uuid)
      this.companyInfo = companyInfoResponse.data
      this.companyName = this.companyInfo.name
      this.email = this.companyInfo.email
      this.phoneNumber = this.companyInfo.phone_number
      this.contractHeader = this.companyInfo.contract_header?.toString() || ''
      this.contractFooter = this.companyInfo.contract_footer?.toString() || ''
      this.website = this.companyInfo.website || ''
      this.backgroundColor = this.companyInfo.background_color
      this.textColor = this.companyInfo.text_color
      this.city = this.companyInfo.city
      this.zipCode = this.companyInfo.zip_code
      this.street = this.companyInfo.street
      this.streetNumber = this.companyInfo.street_number
      this.receiptNote = this.companyInfo.receipt_note || ''
    } finally {
      this.isLoading = false
    }
  }

  public async updateCompany (): Promise<void> {
    this.isLoading = true
    try {
      await BackendConnectionManager.put('companies/' + this.companyInfo.uuid, {
        name: this.companyName,
        phoneNumber: this.phoneNumber,
        city: this.city,
        zipCode: this.zipCode,
        street: this.street,
        streetNumber: this.streetNumber,
        website: this.website,
        backgroundColor: this.backgroundColor,
        textColor: this.textColor,
        contractHeader: this.contractHeader,
        contractFooter: this.contractFooter,
        receiptNote: this.receiptNote
      })
      Toast.success(this.language.lang('done'))
      await updateUserFromWhoAmiI()
      await Router.push({ name: 'ViewCompany' })
    } finally {
      this.isLoading = false
    }
  }

  public async updateAgb (event: Event): Promise<void> {
    this.isLoading = true
    const file = document.getElementById('agb') as HTMLInputElement
    try {
      console.log('Start uploading AGB')
      const formData = new FormData(event.target as HTMLFormElement)
      formData.append('agb', file.value)
      const uploadAgb = await BackendConnectionManager.post<any>(`companies/${this.companyInfo.uuid}/update-agb`, formData, {
        'Content-Type': 'multipart/form-data'
      })
      this.agbFileUrl = uploadAgb.data.url
      await this.openAgb()
      setTimeout(() => location.reload(), 2000)
    } finally {
      this.isLoading = false
    }
  }

  public async loadAgb (): Promise<void> {
    this.isLoading = true
    try {
      const getAgbResponse = await BackendConnectionManager.get<any>(`companies/${this.companyInfo.uuid}/agb`)
      console.log(getAgbResponse.data)
      this.agbFileUrl = getAgbResponse.data.url
    } finally {
      this.isLoading = false
    }
  }

  public async openAgb (): Promise<void> {
    this.isLoading = true
    try {
      await BackendConnectionManager.openInNewTab('../' + this.agbFileUrl.substring(1), 'file.pdf')
    } finally {
      this.isLoading = false
    }
  }

  public async deleteAgb (): Promise<void> {
    this.isLoading = true
    try {
      await BackendConnectionManager.delete(`companies/${this.companyInfo.uuid}/agb`, {})
      location.reload()
    } finally {
      this.isLoading = false
    }
  }
}
