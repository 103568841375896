import BackendConnectionManager from '@/includes/BackendConnectionManager'
import User from '@/interfaces/user'
import store from '@/store'
import Customer from '@/interfaces/customer'
import Company from '@/interfaces/company'
import Contract, { ContractItem } from '@/interfaces/contract'
import Appointment from '@/interfaces/appointment'
import { computed } from 'vue'

export function timeUntilNow (givenTime: string): string {
  const today = new Date(givenTime)
  const christmas = new Date()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const diffMs = (christmas - today) // milliseconds between now & Christmas
  const diffDays = Math.floor(diffMs / 86400000) // days
  const diffHrs = Math.floor((diffMs % 86400000) / 3600000) // hours
  const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000) // minutes
  let returnString = ''
  if (diffDays > 10) {
    return readableDateTime(today.toString())
  }
  if (diffDays > 0) {
    returnString += diffDays + ' days '
  }
  if (diffHrs > 0) {
    returnString += diffHrs + ' Hours '
  }
  if (diffMins > 0) {
    returnString += diffMins + ' Min'
  }
  if (diffDays === 0 && diffMins === 0 && diffHrs === 0) {
    returnString = 'Online'
  }
  return returnString
}
export function parseExpectedTime (expectedTime: string): number[] {
  expectedTime = expectedTime.trim()
  if (expectedTime.indexOf('-') === -1) {
    return parseInt(expectedTime) ? [parseInt(expectedTime)] : []
  }
  const times = expectedTime.trim()
    .split('-')
    .map(w => w.trim())
    .filter(w => w.trim().length > 0)
  const numbers: number[] = []
  for (const time of times) {
    if (parseInt(time) >= 0) {
      numbers.push(parseInt(time))
    }
  }
  return numbers
}
export function isValidExpectedTime (expectedTime: string): boolean {
  const times = parseExpectedTime(expectedTime)
  if (![2].includes(times.length)) {
    return false
  }
  for (const time of times) {
    if (time <= 0) {
      return false
    }
  }
  return true
}
export async function updateUserFromWhoAmiI (): Promise<void> {
  try {
    const whoAmIResponse = await BackendConnectionManager.get<User>(
      'auth/whoami'
    )
    await store.dispatch('setUser', whoAmIResponse.data)
  } catch (error: any) {
    console.log(error.response)
  }
}

export function parseCustomerWithTemplate (content: string, customer: Customer, company: Company, contract?: Contract, appointment?: Appointment, type = 'offer'): string {
  const currentUser = computed<User | null>(() => store.state.user)
  let fullName = ''
  let genderWelcome = ''
  if (customer.language === 'de') {
    fullName = `${customer.gender === 'male' ? 'Herr' : 'Frau'} ${customer.firstname} ${
      customer.lastname
    }`
    genderWelcome = `Sehr geehrte${customer.gender === 'male' ? 'r' : ''}`
  } else {
    fullName = `${customer.gender === 'male' ? 'Mr.' : 'Ms.'} ${customer.firstname} ${
      customer.lastname
    }`
    genderWelcome = 'Dear'
  }
  const email = customer.email || ''
  const phoneNumber = customer.phone_number || ''
  const country = customer.country
  const city = customer.city
  const zipCode = customer.zip_code
  const street = customer.street || ''
  const streetNumber = customer.street_number || ''
  const companyLogo = `<img src="${company.logo}" style="width: 25%;">`
  const acceptLink = '<table width="100%" cellspacing="0" cellpadding="0">\n' +
    '  <tbody><tr>\n' +
    '      <td>\n' +
    '          <table cellspacing="0" cellpadding="0">\n' +
    '              <tbody><tr>\n' +
    '                  <td style="border-radius: 2px;"  bgcolor="#00BC34">\n' +
    '                      <a href="https://www.google.com" target="_blank" style="padding: 8px 12px; border: 1px solid #ED2939;border-radius: 2px;font-family: Helvetica, Arial, sans-serif;font-size: 14px; color: #ffffff;text-decoration: none;font-weight:bold;display: inline-block;">\n' +
    '                          Accept\n' +
    '                      </a>\n' +
    '                  </td>\n' +
    '              </tr>\n' +
    '          </tbody></table>\n' +
    '      </td>\n' +
    '  </tr>\n' +
    '</tbody></table>'
  let returnContent = content
    .replaceAll(/{name}/ig, fullName)
    .replaceAll(/{email}/ig, email)
    .replaceAll(/{phone_number}/ig, phoneNumber)
    .replaceAll(/{country}/ig, country)
    .replaceAll(/{city}/ig, city)
    .replaceAll(/{zip_code}/ig, zipCode)
    .replaceAll(/{street}/ig, street)
    .replaceAll(/{street_number}/ig, streetNumber)
    .replaceAll(/{company_logo}/ig, companyLogo)
    .replaceAll(/{gender_welcome}/ig, genderWelcome)

  if (currentUser.value) {
    returnContent = returnContent.replaceAll(/{employee_name}/ig, `${currentUser.value?.firstname} ${currentUser.value?.lastname}`)
  }

  if (type === 'offer' && contract) {
    returnContent = returnContent.replaceAll(/{accept_link}/ig, acceptLink)
      .replaceAll(/{contract_id}/ig, `#${contract.company_contract_id}`)
      .replaceAll(/{contract_time}/ig, new Date(contract.start_date_time).toLocaleString())
  } else if (type === 'appointment' && appointment) {
    const appointmentTime = new Date(appointment.date_time).toLocaleString()
    returnContent = returnContent.replaceAll(/{appointment_time}/ig, appointmentTime)
  }

  return returnContent.split('\n').join('<br>')
}

export function setTitle (title: string): void {
  document.title = `Transport CRM | ${title}`
}

export function readableDateTime (dateTime: string): string {
  const givenDate = new Date(dateTime)
  if ((new URL(document.URL).hostname) !== 'localhost') {
    givenDate.setHours(givenDate.getHours())
  }
  let day = givenDate.getDate().toString()
  if (day.length === 1) {
    day = `0${day}`
  }
  let month = (givenDate.getMonth() + 1).toString()
  if (month.length === 1) {
    month = `0${month}`
  }
  let hours = givenDate.getHours().toString()
  if (hours.length === 1) {
    hours = `0${hours}`
  }
  let minutes = givenDate.getMinutes().toString()
  if (minutes.length === 1) {
    minutes = `0${minutes}`
  }
  return `${day}-${month}-${givenDate.getFullYear()}, ${hours}:${minutes}`
}

export function moveToBottom (): void {
  window.$(document.scrollingElement || document.body).animate(
    {
      scrollTop: document.body.scrollHeight
    },
    400
  )
}

export function calculateDiscount (item: ContractItem): number {
  if (!item.amount || !item.unit_price || !item.discount) {
    return 0
  }
  return (item.amount * item.unit_price) * (item.discount / 100)
}

export function calculateItemFinalPrice (item: ContractItem, allItems?: ContractItem[]): number {
  if (item.type === 'item' || item.type === 'product') {
    return ((item.unit_price || 0) * (item.amount || 0)) - calculateDiscount(item)
  } else if (item.type === 'discount') {
    if (!allItems) {
      throw new Error('All items should be provided with the discount')
    }
    if (item.amount && item.amount > 0) {
      return item.amount * -1
    }
    return (allItems
      .filter((it) => it.order < item.order)
      .map((it) => calculateItemFinalPrice(it, allItems))
      .reduce((a, b) => a + b, 0) * ((item.discount || 0) / 100)) * -1
  }
  return 0
}
