
import { Options, Vue } from 'vue-class-component'
import Loading from '@/components/Loading.vue'
import Pagination from '@/components/Pagination.vue'
import Language from '@/includes/language'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import ApiResource, { Meta } from '@/interfaces/api_resource'
import { Product } from '@/interfaces/product'
import DeleteModal from '@/components/DeleteModal.vue'
import Alert from '@/components/Alert.vue'
import jquery from '@/includes/jquery'

@Options({
  name: 'Products',
  components: { Loading, Pagination, DeleteModal, Alert }
})
export default class ProductsList extends Vue {
  isLoading = false;
  language = Language;
  products: Product[] = [];
  meta: Meta = {} as Meta;
  newProductInfo = {
    name: '',
    price: 0,
    isEdit: false,
    language: 'all',
    uuid: ''
  }

  public async mounted (): Promise<void> {
    this.isLoading = false
    await this.loadProducts()
  }

  public submit (): void {
    if (this.newProductInfo.isEdit) {
      this.editProduct()
      return
    }
    this.newProduct()
  }

  public async loadProducts (page = 1): Promise<void> {
    this.isLoading = true
    try {
      const productsResponse = await BackendConnectionManager.get<ApiResource<Product>>('products?page=' + page)
      this.products = productsResponse.data.data
      this.meta = productsResponse.data.meta
    } catch (error: any) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }

  public async newProduct (): Promise<void> {
    this.isLoading = true
    try {
      await BackendConnectionManager.post<void>('products', this.newProductInfo)
      jquery('#newProductModal').modal('hide')
      await this.loadProducts()
      this.resetNewProduct()
    } catch (error: any) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }

  public async showEditModal (uuid: string): Promise<void> {
    const productInfo = this.products.find((product) => product.uuid === uuid)
    if (!productInfo) {
      return
    }
    this.newProductInfo = {
      name: productInfo.name,
      price: productInfo.price,
      language: productInfo.language,
      isEdit: true,
      uuid: uuid
    }
    jquery('#newProductModal').modal('show')
  }

  public async editProduct (): Promise<void> {
    if (!this.newProductInfo.uuid) {
      return
    }
    this.isLoading = true
    try {
      await BackendConnectionManager.put<void>('products/' + this.newProductInfo.uuid, this.newProductInfo)
      jquery('#newProductModal').modal('hide')
      this.resetNewProduct()
      this.loadProducts()
    } catch (error: any) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }

  public async resetNewProduct (): Promise<void> {
    this.newProductInfo = {
      name: '',
      language: 'all',
      price: 0,
      isEdit: false,
      uuid: ''
    }
  }

  public async deleteProduct (uuid: string): Promise<void> {
    this.isLoading = true
    try {
      await BackendConnectionManager.delete('products/' + uuid, {})
      await this.loadProducts()
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }
}
