
import { Options, Vue } from 'vue-class-component'
import Loading from '@/components/Loading.vue'
import { computed, ComputedRef } from 'vue'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import User from '@/interfaces/user'
import Router from '@/router'
import { useRoute } from 'vue-router'
import Toast from '@/includes/toast'
import { Languages } from '@/includes/types'
import Language from '@/includes/language'
import store from '@/store'
import { setTitle } from '@/includes/functions'

@Options({
  name: 'NewEmployee',
  components: { Loading }
})
export default class NewEmployee extends Vue {
  currentUser = {} as User;
  language = Language;
  isLoading = false;
  fullName = '';
  uuid = '';
  email = '';
  firstname = '';
  lastname = '';
  password = '';
  passwordConfirmation = '';
  phone = '';
  role = 'user';
  selectedLanguage = 'de';
  informUserByEmail = true;
  canSubmit: ComputedRef<boolean> = computed(() => false);
  public async mounted (): Promise<void> {
    setTitle(this.language.lang('edit'))
    this.isLoading = false
    this.canSubmit = computed(() => {
      return this.firstname.trim().length > 2 &&
        this.lastname.trim().length > 2 &&
        this.email.trim().length > 3 &&
        (this.password.trim().length > 0 || this.passwordConfirmation.trim().length > 0
          ? this.password.trim().length > 5 && this.passwordConfirmation.trim() === this.password.trim() : true) &&
        ['en', 'de'].includes(this.selectedLanguage.trim()) &&
        ['user', 'owner'].includes(this.role.trim())
    })
    const route = useRoute()
    const employeeId = computed(() => route.params.employeeId)
    if (employeeId.value) {
      await this.loadUser(employeeId.value.toString())
      setTitle(this.language.lang('edit') + ` ${this.firstname} ${this.lastname}`)
    }
    const currentUser = computed<User | null>(() => store.state.user)
    if (currentUser.value) {
      this.currentUser = currentUser.value
    }
  }

  public async editNewUser (): Promise<void> {
    this.isLoading = true
    try {
      const newUserResponse = await BackendConnectionManager.put<User>('users/' + this.uuid, {
        email: this.email,
        password: this.password,
        firstname: this.firstname,
        lastname: this.lastname,
        phone: this.phone,
        language: this.selectedLanguage,
        role: this.role,
        informUserByEmail: this.informUserByEmail
      })
      this.language.changeLanguage(newUserResponse.data.language === 'de' ? Languages.Germany : Languages.English)
      Toast.success(this.language.lang('done'))
      await Router.push({ name: 'Employee', params: { employeeId: this.uuid } })
      return
    } catch (error: any) {
      // eslint-disable-next-line no-unused-expressions
      error.response?.data?.errors?.forEach((singleError: { message: string }) => {
        Toast.danger(singleError.message)
      })
    } finally {
      this.isLoading = false
    }
  }

  public async loadUser (userId: string): Promise<void> {
    this.isLoading = true
    try {
      const userResponse = await BackendConnectionManager.get<User>('users/' + userId)
      const userInfo = userResponse.data
      this.uuid = userId
      this.firstname = userInfo.firstname
      this.lastname = userInfo.lastname
      this.email = userInfo.email
      this.phone = userInfo.phone_number || ''
      this.selectedLanguage = userInfo.language
      this.fullName = `${this.firstname} ${this.lastname}`
      this.role = userInfo.role
    } catch (error: any) {
      // eslint-disable-next-line no-unused-expressions
      error.response?.data?.errors?.forEach((singleError: { message: string }) => {
        Toast.danger(singleError.message)
      })
    } finally {
      this.isLoading = false
    }
  }
}
