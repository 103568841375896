
import { Options, Vue } from 'vue-class-component'
import Language from '@/includes/language'
import Loading from '@/components/Loading.vue'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import ApiResource, { Meta } from '@/interfaces/api_resource'
import Pagination from '@/components/Pagination.vue'
import { computed } from 'vue'
import User from '@/interfaces/user'
import store from '@/store'
import Appointment from '@/interfaces/appointment'
import { setTitle, readableDateTime } from '@/includes/functions'

@Options({
  name: 'AppointmentsList',
  components: { Loading, Pagination }
})
export default class AppointmentsList extends Vue {
  readableDateTime = readableDateTime;
  language = Language;
  isLoading = false;
  totalAppointments = 0;
  appointments: Appointment[] = []
  meta = {} as Meta;
  currentUser = {} as User;
  public async mounted (): Promise<void> {
    this.isLoading = false
    const user = computed<User | null>(() => store.state.user)
    if (user.value) {
      this.currentUser = user.value
    }
    setTitle(this.language.lang('appointments'))
    await this.loadAppointments()
  }

  public async loadAppointments (page = 1): Promise<void> {
    this.isLoading = true
    try {
      const usersResponse = await BackendConnectionManager.get<ApiResource<Appointment>>('appointments?page=' + page)
      this.appointments = usersResponse.data.data
      this.meta = usersResponse.data.meta
      this.totalAppointments = usersResponse.data.meta.total
    } finally {
      this.isLoading = false
    }
  }
}
