import { createToast } from 'mosha-vue-toastify'
enum ToastType {
  Info = 'info',
  Default = 'default',
  Warning = 'warning',
  Success = 'success',
  Danger = 'danger',
}
export default new (class Toast {
  private static runToast (
    content: string,
    type: ToastType = ToastType.Info
  ): void {
    createToast(content, {
      hideProgressBar: true,
      showIcon: true,
      type: type,
      transition: 'slide'
    })
  }

  public info (content: string): void {
    Toast.runToast(content)
  }

  public default (content: string): void {
    Toast.runToast(content, ToastType.Default)
  }

  public success (content: string): void {
    Toast.runToast(content, ToastType.Success)
  }

  public warning (content: string): void {
    Toast.runToast(content, ToastType.Warning)
  }

  public danger (content: string): void {
    Toast.runToast(content, ToastType.Danger)
  }
})()
