
import { Options, Vue } from 'vue-class-component'
import Language from '@/includes/language'
import Loading from '@/components/Loading.vue'
import { computed, ComputedRef, watch } from 'vue'
import Customer from '@/interfaces/customer'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import ApiResource from '@/interfaces/api_resource'
import Toast from '@/includes/toast'
import Router from '@/router'
import User from '@/interfaces/user'
import store from '@/store'
import { parseCustomerWithTemplate, setTitle } from '@/includes/functions'
import Contract from '@/interfaces/contract'
import Appointment from '@/interfaces/appointment'

@Options({
  name: 'New Mail Template',
  components: { Loading }
})
export default class NewMailTemplate extends Vue {
  language = Language;
  isLoading = false;
  title = '';
  body = ''
  selectedLanguage = 'de';
  type: 'offer' | 'offerConfirmation' | 'appointment' | 'others' = 'offer';
  canSubmit: ComputedRef<boolean> = computed(() => false);
  contentEditorTab = 'editor'
  selectedCustomerInfo = {} as Customer;
  preview = '';
  customers: Customer[] = [];
  userInfo = {} as User;
  templateTag: string[] = [
    '{gender_welcome}',
    '{name}',
    '{employee_name}',
    '{email}',
    '{phone_number}',
    '{country}',
    '{city}',
    '{zip_code}',
    '{street}',
    '{street_number}'
  ];

  availableTags: string[] = [];

  specialOfferTags = [
    '{accept_link}',
    '{contract_id}',
    '{contract_time}'
  ]

  specialAppointmentsTags = [
    '{appointment_time}'
  ]

  specialOfferConfirmationTags = [
    '{contract_id}',
    '{contract_time}'
  ]

  public async mounted (): Promise<void> {
    setTitle(this.language.lang('newMailTemplate'))
    this.isLoading = false
    this.canSubmit = computed(() => {
      return this.title.trim().length > 3 && this.body.trim().length > 50
    })
    const templateType = computed(() => this.type)
    watch(templateType, (type) => {
      switch (type) {
        case 'offer':
          this.availableTags = [...this.templateTag, ...this.specialOfferTags]
          break
        case 'appointment':
          this.availableTags = [...this.templateTag, ...this.specialAppointmentsTags]
          break
        case 'offerConfirmation':
          this.availableTags = [...this.templateTag, ...this.specialOfferConfirmationTags]
          break
        default:
          this.availableTags = [...this.templateTag]
          break
      }
    })

    const user = computed<User | null>(() => store.state.user)
    if (user.value) {
      this.userInfo = user.value
    }
    this.availableTags = [...this.templateTag, ...this.specialOfferTags]
    await this.loadCustomers()
  }

  public async addTagToTemplate (tag: string): Promise<void> {
    this.body = this.body + tag
  }

  public async createNewMailTemplate (): Promise<void> {
    this.isLoading = true
    try {
      await BackendConnectionManager.post('mail-templates', {
        title: this.title,
        language: this.selectedLanguage,
        body: this.body,
        type: this.type
      })
      Toast.success(this.language.lang('done'))
      await Router.push({ name: 'MailTemplates' })
    } finally {
      this.isLoading = false
    }
  }

  public async loadCustomers (page = 1): Promise<void> {
    this.isLoading = true
    try {
      const usersResponse = await BackendConnectionManager.get<ApiResource<Customer>>(
        'customers?page=' + page
      )
      this.customers = usersResponse.data.data
      if (this.customers.length > 0) {
        this.selectedCustomerInfo = this.customers[0]
      }
    } finally {
      this.isLoading = false
    }
  }

  public parseCustomerWithTemplate (): void {
    this.preview = parseCustomerWithTemplate(this.body, this.selectedCustomerInfo, this.userInfo.company!, {
      id: 2023,
      start_date_time: new Date()
    } as unknown as Contract, { date_time: (new Date()).toString() } as Appointment, this.type)
  }
}
