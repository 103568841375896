/* eslint-disable camelcase */
import User from '@/interfaces/user'
import Customer from '@/interfaces/customer'
import Address from '@/interfaces/address'
import Vehicle from '@/interfaces/vehicle'
import Company from '@/interfaces/company'

export const contractItemType = ['item', 'product', 'text', 'discount', 'subtotal'] as const

export interface ContractItem {
    uuid: string;
    name: string;
    unit_price?: number;
    type: typeof contractItemType[number];
    amount?: number;
    order: number;
    discount?: number;
    created_at: string;
    updated_at: string;
}

export default interface Contract {
  id: number;
  company_contract_id: number;
  cleaning_date_time
    :
    'select' | 'open' | 'no-cleaning'
  cleaning_extra_notes
    :
    string;
  cleaning_price
    :
    number
  cleaning_rooms
    :
    number;
  cleaning_end_date_time
    :
    string;
  company_id
    :
    string;
  created_at
    :
    string;
  created_by
    :
    string;
  customer_id
    :
    string;
  deleted
    :
    0
  expected_time
    :
    string
  extra_notes
    :
    string;
  flat_rate_price
    :
    number
  hour_price
    :
    number
  include_mwst
    :
    1 | 0
  is_flat_rate
    :
    boolean
  arrival_and_return_price
    :
    number
  rooms
    :
    number
  start_date_time
    :
    string;
  status
    :
    'open' | 'accepted';
  trash_extra_notes
    :
    string;
  trash_flat_rate_price
    :
    number;
  trash_include_mwst
    :
    1 | 0
  trash_price_pro_m
    :
    number
  updated_at
    :
    string;
  uuid
    :
    string;
  vehicles
    :
    number
  workers
    :
    number
  price_type
    :
    string
  max_price
    :
    number
  workers_food
    :
    number
  company?: Company;
  customer?: Customer;
  creator?: User;
  addresses: Address[]
  vehicles_list?: Vehicle[]
  disassembly
:
string | null
items: ContractItem[];
  lamp_assemply: number;
  wall_mounting: number;
  furniture_lift: number;
  washing_tower_available: number;
  fill_dowel_holes: number;
  high_pressure_cleaning: number;
  contract_header: string | null;
  contract_footer: string | null;
  type_of_floor: string | null;
  type_of_blinds: string | null;
  additional_rooms: string | null;
  area_for_pressure_cleaning: number;
}
/**
 * api.containers.hxi.ch
 * www.domain.ht.co
 */
