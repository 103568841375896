
import { Options, Vue } from 'vue-class-component'
import Language from '@/includes/language'
import Loading from '@/components/Loading.vue'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import MailTemplate from '@/interfaces/mail_template'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import Toast from '@/includes/toast'
import Router from '@/router'
import User from '@/interfaces/user'
import store from '@/store'
import { readableDateTime, setTitle } from '@/includes/functions'

@Options({
  name: 'View Mail Template',
  components: { Loading }
})
export default class ViewMailTemplate extends Vue {
  readableDateTime = readableDateTime;
  language = Language;
  isLoading = false;
  templateInfo = {} as MailTemplate;
  currentUser = {} as User;

  public async mounted (): Promise<void> {
    this.isLoading = false
    const user = computed<User | null>(() => store.state.user)
    if (user.value) {
      this.currentUser = user.value
    }
    const route = useRoute()
    const templateId = computed(() => route.params.templateId)
    if (templateId.value) {
      await this.loadTemplateInfo(templateId.value.toString())
      setTitle(this.language.lang('mailTemplate') + ` ${this.templateInfo.title}`)
    }
  }

  public async loadTemplateInfo (templateId: string): Promise<void> {
    this.isLoading = true
    try {
      const templateResponse = await BackendConnectionManager.get<MailTemplate>('mail-templates/' + templateId)
      this.templateInfo = templateResponse.data
    } finally {
      this.isLoading = false
    }
  }

  public async deleteMailTemplate (): Promise<void> {
    this.isLoading = true
    try {
      await BackendConnectionManager.delete('mail-templates/' + this.templateInfo.uuid, {})
      Toast.success(this.language.lang('deleted'))
      await Router.push({ name: 'MailTemplates' })
    } finally {
      this.isLoading = false
    }
  }
}
