
import { Options, Vue } from 'vue-class-component'
import Dynamic from '@/components/contract_templates/dynamic.vue'
import Language from '@/includes/language'
import { setTitle } from '@/includes/functions'

@Options({
  name: 'NewContract',
  components: { Dynamic }
})
export default class NewContract extends Vue {
  language = Language;
  template: 'dynamic' | 'standard' = 'dynamic';
  public async mounted (): Promise<void> {
    this.template = 'dynamic'
    setTitle(this.language.lang('newContract'))
  }
}
