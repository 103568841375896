
import { Options, Vue } from 'vue-class-component'
import Language from '@/includes/language'
import Loading from '@/components/Loading.vue'
import { setTitle } from '@/includes/functions'

@Options({
  name: 'Edit Mail Template',
  components: { Loading }
})
export default class EditMailTemplate extends Vue {
  language = Language;
  isLoading = false;
  public async mounted (): Promise<void> {
    setTitle(this.language.lang('editMailTemplate'))
  }
}
