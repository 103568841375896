
import { Options, Vue } from 'vue-class-component'
import Loading from '@/components/Loading.vue'
import Language from '@/includes/language'
import { useRoute } from 'vue-router'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import { computed } from 'vue'
import Appointment from '@/interfaces/appointment'
import Toast from '@/includes/toast'
import Router from '@/router'
import User from '@/interfaces/user'
import store from '@/store'
import { readableDateTime, setTitle } from '@/includes/functions'

@Options({
  name: 'ViewEmployee',
  components: { Loading }
})
export default class ViewCustomer extends Vue {
  readableDateTime = readableDateTime;
  language = Language;
  appointmentInfo = {} as Appointment;
  isLoading = true;
  currentUser = {} as User;

  public async mounted (): Promise<void> {
    this.isLoading = false
    const user = computed<User | null>(() => store.state.user)
    if (user.value) {
      this.currentUser = user.value
    }
    const route = useRoute()
    const appointmentId = computed(() => route.params.appointmentId)
    if (appointmentId.value) {
      await this.loadAppointment(appointmentId.value.toString())
    }
  }

  public async deleteAppointment (): Promise<void> {
    this.isLoading = true
    try {
      await BackendConnectionManager.delete('appointments/' + this.appointmentInfo.uuid, {})
      Toast.success(this.language.lang('deleted'))
      await Router.push({ name: 'Appointments' })
    } finally {
      this.isLoading = false
    }
  }

  public async loadAppointment (appointmentId: string): Promise<void> {
    this.isLoading = true
    try {
      const appointmentResponse = await BackendConnectionManager.get<Appointment>('appointments/' + appointmentId)
      this.appointmentInfo = appointmentResponse.data
      setTitle(this.language.lang('appointments') + ` ${this.appointmentInfo.customer?.firstname} ${this.appointmentInfo.customer?.lastname}`)
    } finally {
      this.isLoading = false
    }
  }
}
