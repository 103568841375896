
import { Options, Vue } from 'vue-class-component'
import Loading from '@/components/Loading.vue'
import Language from '@/includes/language'
import Customer from '@/interfaces/customer'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import Pagination from '@/components/Pagination.vue'
import { computed, ComputedRef, watch, nextTick } from 'vue'
import Jquery from '@/includes/jquery'
import Toast from '@/includes/toast'
import Router from '@/router'
import { isValidExpectedTime, parseExpectedTime } from '@/includes/functions'
import { useRoute } from 'vue-router'
import Contract, { ContractItem } from '@/interfaces/contract'
import Address from '@/interfaces/address'
import User from '@/interfaces/user'
import store from '@/store'
import AddressesForm from '@/components/Contracts/AddressesForm.vue'
import Vehicle from '@/interfaces/vehicle'
import VehicleForm from '../Contracts/VehicleForm.vue'
import ContractItemsForm from '../ItemsForm.vue'

@Options({
  name: 'EditContractDynamic',
  components: {
    Loading,
    Pagination,
    AddressesForm,
    VehicleForm,
    ContractItemsForm
  }
})
export default class EditContractDynamic extends Vue {
  language = Language
  isLoading = false
  // customer information
  selectedCustomerInfo = {} as Customer

  contractHeader = '';
  contractFooter = '';

  // start relocation information
  startDateTime = new Date().toISOString().split('T')[0] + 'T08:00'
  workers = ''
  disassembly = ''
  workersFood = 0.0
  vehicles: ComputedRef<number> = computed(() => 0)
  hourPrice = 0.0
  expectedTime = '0-0'
  expectedTimeParsed = {
    from: 0,
    to: 0
  }

  furnitureLift = false;
  wallMounting = false;
  lampAssemply = false;

  flatRatePrice = 0
  maxPrice = 0
  arrivalAndReturnPrice = 0
  relocationRooms = ''
  includeMWST = false
  trashIncludeMWST = false
  priceType: 'flatRate' | 'maxPrice' | 'hourly' = 'hourly'
  transportExtraNotes = ''
  // end relocation information

  // start trash information
  trashPriceProMeter = 0
  trashFlatRatePrice = 0
  trashExtraNotes = ''
  // end trash information

  // start cleaning information
  cleaningExtraNotes = ''
  cleaningDateTime: 'open' | 'select' | 'no-cleaning' = 'no-cleaning'
  cleaningEndDateTime = new Date().toISOString().split('T')[0] + 'T08:00'
  cleaningPrice = 0.0
  cleaningRooms = 0.0
  typeOfFloor = ''
  typeOfBlinds = ''
  additionalRooms = ''
  washingTowerAvailable = false;
  fillDowelHoles = false;
  highPressureCleaning = false;
  areaForPressureCleaning = 0;
  // end cleaning information

  contractItems: ContractItem[] = []
  showItems = false;

  newFromAddress = {
    uuid: '',
    city: '',
    zipCode: '',
    street: '',
    streetNumber: '',
    floor: 0,
    elevator: 'no' as 'yes' | 'no',
    buildingType: '',
    country: 'CH'
  }

  newToAddress = {
    uuid: '',
    city: '',
    zipCode: '',
    street: '',
    streetNumber: '',
    floor: 0,
    elevator: 'no' as 'yes' | 'no',
    buildingType: '',
    country: 'CH'
  }

  fromAddress: Address[] = []

  toAddress: Address[] = []

  canCreateNewContract: ComputedRef<boolean> = computed(() => false)
  canDoFirstNext: ComputedRef<boolean> = computed(() => false)
  canDoThirdNext: ComputedRef<boolean> = computed(() => false)
  canDoTrashNext: ComputedRef<boolean> = computed(() => false)
  isValidExpectedTimeValue = false;
  formStepsNumber = 1
  formSteps: ComputedRef<number> = computed(() => this.formStepsNumber)

  allVehicles: { uuid: string; count: number; name: string }[] = []

  buildingTypeAutoComplete: string[] = [];
  vehiclesAutoComplete: string[] = [];
  disassemblyAutoComplete: string[] = [];
  contractInfo = {} as Contract;
  hidePages: string[] = [];

  public async mounted (): Promise<void> {
    const route = useRoute()
    const contractId = computed(() => route.params.contractId)
    this.canCreateNewContract = computed<boolean>(() => {
      if (!this.canDoFirstNext || !this.canDoThirdNext || !this.canDoTrashNext) {
        return false
      } else if (this.cleaningDateTime !== 'no-cleaning') {
        if (this.cleaningExtraNotes.trim().length > 500) {
          return false
        } else if ([this.cleaningRooms.toString().trim(), this.cleaningPrice.toString().trim()].includes('')) {
          return false
        } else if (this.cleaningRooms <= 0 || this.cleaningPrice <= 0) {
          return false
        } else if (
          this.cleaningDateTime === 'select' &&
          this.cleaningEndDateTime.trim() === ''
        ) {
          return false
        }
      }
      return true
    })
    this.canDoFirstNext = computed<boolean>(() => {
      return ![this.fromAddress.length, this.toAddress.length].includes(0)
    })
    this.canDoThirdNext = computed<boolean>(() => {
      if (this.startDateTime === '') {
        return false
      } else if (
        this.workers.toString().trim() === '' || parseInt(this.workers) <= 0 ||
        this.allVehicles.length <= 0 ||
        this.relocationRooms.toString().trim() === '' || parseFloat(this.relocationRooms) <= 0
      ) {
        return false
      } else if (this.transportExtraNotes.trim().length > 500) {
        return false
      }

      if (!isValidExpectedTime(this.expectedTime)) {
        return false
      } else if (this.priceType === 'hourly') {
        if (this.hourPrice <= 0) {
          return false
        }
      } else if (this.priceType === 'flatRate') {
        if (this.flatRatePrice <= 0) {
          return false
        }
      } else if (this.priceType === 'maxPrice') {
        if (this.maxPrice <= 0) {
          return false
        }
      }
      return true
    })
    this.canDoTrashNext = computed<boolean>(() => {
      return !([this.trashFlatRatePrice.toString().trim(), this.trashPriceProMeter.toString().trim()].includes('')) &&
        this.trashPriceProMeter >= 0 && this.trashFlatRatePrice >= 0
    })
    this.vehicles = computed<number>(() => {
      let vehiclesCount = 0
      for (const vehicle of this.allVehicles) {
        vehiclesCount += vehicle.count
      }
      return vehiclesCount
    })
    this.loadAutoCompleteWords().catch()
    const formSteps = computed(() => this.formStepsNumber)
    watch<number>(formSteps, (newStep: number, oldStep: number) => {
      if (newStep === oldStep) {
        return
      }
      if (newStep > oldStep) {
        Jquery('#newContractSteps').carousel('next')
      } else {
        Jquery('#newContractSteps').carousel('prev')
      }
    })
    const watchExpectedTime = computed<string>(() => this.expectedTime)
    watch<string>(watchExpectedTime, (newValue: string) => {
      if (isValidExpectedTime(newValue)) {
        this.isValidExpectedTimeValue = true
        const times = parseExpectedTime(newValue)
        this.expectedTimeParsed.from = times[0] || 0
        this.expectedTimeParsed.to = times[1] || 0
      } else {
        this.isValidExpectedTimeValue = false
        this.expectedTimeParsed.from = 0
        this.expectedTimeParsed.to = 0
      }
    })
    if (contractId.value) {
      await this.loadContractInfo(contractId.value.toString())
    }
    const currentUser = computed<User | null>(() => store.state.user)
    if (currentUser.value) {
      this.hidePages = currentUser.value?.company?.hide_new_contract_page.split(',').map(t => t.trim()) || []
    }
  }

  public async loadContractInfo (contractId: string): Promise<void> {
    this.isLoading = true
    try {
      const contractInfoResponse = await BackendConnectionManager.get<Contract>('contracts/' + contractId)
      const contractInfo = contractInfoResponse.data
      this.contractInfo = contractInfo
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.selectedCustomerInfo = contractInfo.customer!
      this.fromAddress = contractInfo.addresses
        .filter((ad) => ad.direction === 'from')
      this.toAddress = contractInfo.addresses
        .filter((ad) => ad.direction === 'to')
      this.startDateTime = this.addHourToTime(contractInfo.start_date_time.split(':00.000')[0])
      this.workers = (contractInfo.workers || 0).toString()
      this.priceType = contractInfo.price_type as 'flatRate' | 'maxPrice' | 'hourly'
      this.hourPrice = contractInfo.hour_price || 0
      this.expectedTime = contractInfo.expected_time || '0-0'
      this.flatRatePrice = contractInfo.flat_rate_price || 0
      this.maxPrice = contractInfo.max_price || 0
      this.arrivalAndReturnPrice = contractInfo.arrival_and_return_price || 0
      this.workersFood = contractInfo.workers_food || 0
      this.includeMWST = contractInfo.include_mwst === 1 || false
      this.relocationRooms = (contractInfo.rooms || 0).toString()
      this.allVehicles = (contractInfo.vehicles_list || []).filter(v => v.for === 'relocation').map(v => {
        return {
          uuid: v.uuid,
          name: v.name,
          count: v.count
        }
      })
      this.transportExtraNotes = contractInfo.extra_notes || ''
      // end relocation information
      // start trash information
      this.trashPriceProMeter = contractInfo.trash_price_pro_m || 0
      this.trashFlatRatePrice = contractInfo.trash_flat_rate_price || 0
      this.trashExtraNotes = contractInfo.trash_extra_notes || ''
      this.trashIncludeMWST = (contractInfo.trash_include_mwst === 1) || false
      // end trash information
      // start cleaning information
      if (contractInfo.cleaning_date_time !== 'no-cleaning') {
        this.cleaningRooms = contractInfo.cleaning_rooms || 0
        this.cleaningDateTime = contractInfo.cleaning_date_time || 'no-cleaning'
        this.cleaningPrice = contractInfo.cleaning_price || 0
        this.cleaningExtraNotes = contractInfo.cleaning_extra_notes || ''
        this.cleaningEndDateTime = this.addHourToTime((contractInfo.cleaning_end_date_time || '').split(':00.000')[0])
      }
      this.disassembly = contractInfo.disassembly || ''
      this.contractItems = contractInfo.items
      this.lampAssemply = contractInfo.lamp_assemply > 0
      this.wallMounting = contractInfo.wall_mounting > 0
      this.furnitureLift = contractInfo.furniture_lift > 0
      this.contractHeader = contractInfo.contract_header || ''
      this.contractFooter = contractInfo.contract_footer || ''
      this.typeOfFloor = contractInfo.type_of_floor || ''
      this.typeOfBlinds = contractInfo.type_of_blinds || ''
      this.additionalRooms = contractInfo.additional_rooms || ''
      this.washingTowerAvailable = contractInfo.washing_tower_available > 0
      this.fillDowelHoles = contractInfo.fill_dowel_holes > 0
      this.highPressureCleaning = contractInfo.high_pressure_cleaning > 0
      this.areaForPressureCleaning = contractInfo.area_for_pressure_cleaning
      await nextTick()
      this.showItems = true
    } finally {
      this.isLoading = false
    }
  }

  public addHourToTime (dateTime: string | null): string {
    if (!dateTime || dateTime.length === 0) {
      return ''
    }
    // 2023-01-18T07:00
    const splitDateTime = dateTime.split('T')
    const time = splitDateTime[1] // get the time
    const hoursMinutes = time.split(':')
    let hour = (parseInt(hoursMinutes[0]) + 1).toString()
    if (hour.length === 1) {
      hour = `0${hour}`
    }
    return `${splitDateTime[0]}T${hour}:${hoursMinutes[1]}`
  }

  public nextStep (): void {
    this.formStepsNumber++
    this.formSteps = computed(() => this.formStepsNumber)
    window.$(document.scrollingElement || document.body).animate(
      {
        scrollTop: 0
      },
      400
    )
  }

  public prevStep (): void {
    this.formStepsNumber--
    this.formSteps = computed(() => this.formStepsNumber)
    window.$(document.scrollingElement || document.body).animate(
      {
        scrollTop: 0
      },
      400
    )
  }

  public updateFromAddress (addresses: Address[]): void {
    this.fromAddress = addresses
  }

  public updateToAddress (addresses: Address[]): void {
    this.toAddress = addresses
  }

  public async updateContract (): Promise<void> {
    this.isLoading = true
    try {
      await BackendConnectionManager.put('contracts/' + this.contractInfo.id, {
        customerId: this.selectedCustomerInfo.uuid,
        isTherePackaging: false,
        // start relocation
        startDateTime: this.startDateTime,
        workers: this.workers,
        priceType: this.priceType,
        flatRatePrice: this.flatRatePrice,
        hourPrice: this.hourPrice,
        expectedTime: this.expectedTime.trim() === '' ? '0-0' : this.expectedTime.trim(),
        arrivalAndReturnPrice: this.arrivalAndReturnPrice,
        includeMwst: this.includeMWST,
        relocationRooms: this.relocationRooms,
        extraNotes: this.transportExtraNotes,
        maxPrice: this.maxPrice,
        allVehicles: this.allVehicles,
        workersFood: this.workersFood,
        // end relocation
        trashIncludeMwst: this.trashIncludeMWST,
        trashExtraNotes: this.trashExtraNotes,
        trashFlatRatePrice: this.trashFlatRatePrice,
        trashPriceProM: this.trashPriceProMeter,
        cleaning: this.cleaningDateTime,
        cleaningEndDateTime: this.cleaningEndDateTime,
        cleaningRooms: this.cleaningRooms,
        cleaningPrice: this.cleaningPrice,
        cleaningExtraNotes: this.cleaningExtraNotes,
        disassembly: this.disassembly,
        items: this.contractItems,
        fromAddresses: this.fromAddress.map((ad) => {
          if (ad.building_type?.trim().length === 0) {
            ad.building_type = null
          }
          return ad
        }),
        toAddresses: this.toAddress.map((ad) => {
          if (ad.building_type?.trim().length === 0) {
            ad.building_type = null
          }
          return ad
        }),
        lampAssemply: this.lampAssemply,
        wallMounting: this.wallMounting,
        furnitureLift: this.furnitureLift,
        contractHeader: this.contractHeader,
        contractFooter: this.contractFooter,
        typeOfFloor: this.typeOfFloor,
        typeOfBlinds: this.typeOfBlinds,
        additionalRooms: this.additionalRooms,
        washingTowerAvailable: this.washingTowerAvailable,
        fillDowelHoles: this.fillDowelHoles,
        highPressureCleaning: this.highPressureCleaning,
        areaForPressureCleaning: this.areaForPressureCleaning
      })
      Toast.success(this.language.lang('contractCreatedSuccess'))
      await Router.push({ name: 'ViewContract', params: { contractId: this.contractInfo.id } })
      return
    } catch (error: any) {
      console.log(error)
      // eslint-disable-next-line no-unused-expressions
      error.response?.data?.errors?.forEach((singleError: { message: string }) => {
        Toast.danger(singleError.message)
      })
    } finally {
      this.isLoading = false
    }
  }

  public async loadAutoCompleteWords (): Promise<void> {
    this.isLoading = true
    try {
      const specialProductAutoComplete = await BackendConnectionManager.get<{ disassemblies: string[], buildingTypes: string[], vehicles: string[] }>('auto-complete/all')
      this.buildingTypeAutoComplete = specialProductAutoComplete.data.buildingTypes
      this.vehiclesAutoComplete = specialProductAutoComplete.data.vehicles
      this.disassemblyAutoComplete = specialProductAutoComplete.data.disassemblies
    } finally {
      this.isLoading = false
    }
  }

  public updateVehicle (vehicles: Vehicle[]): void {
    this.allVehicles = vehicles
  }

  public updateContractItems (items: ContractItem[]): void {
    this.contractItems = items
  }
}
