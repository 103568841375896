
import { Options, Vue } from 'vue-class-component'
import Loading from '@/components/Loading.vue'
import Pagination from '@/components/Pagination.vue'
import Language from '@/includes/language'
import BackendConnectionManager from '@/includes/BackendConnectionManager'

@Options({
  name: 'NewProducts',
  components: { Loading, Pagination }
})
export default class newProduct extends Vue {
  isLoading = false;
  language = Language;
  name = '';
  price = 0;

  public async mounted (): Promise<void> {
    this.isLoading = false
  }

  public async newProduct (): Promise<void> {
    this.isLoading = true
    try {
      await BackendConnectionManager.post<void>('products', {
        name: this.name,
        price: this.price
      })
    } catch (error: any) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }
}
