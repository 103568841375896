
import { Options, Vue } from 'vue-class-component'
import Loading from '@/components/Loading.vue'
import Language from '@/includes/language'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import ContractTemplate from '@/interfaces/ContractTemplate'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { readableDateTime, setTitle } from '@/includes/functions'

@Options({
  name: 'View Contract Template',
  components: { Loading }
})
export default class ViewContractTemplate extends Vue {
  readableDateTime = readableDateTime;
  language = Language;
  isLoading = true;
  currentTemplateId = '';
  contractId = 0;

  contractTemplate = {} as ContractTemplate;

  public async mounted (): Promise<void> {
    this.isLoading = false
    const route = useRoute()
    const templateId = computed(() => route.params.templateId)
    setTitle(this.language.lang('contractTemplate'))
    if (templateId.value) {
      this.currentTemplateId = templateId.value.toString()
      await this.loadContractTemplate(templateId.value.toString())
    }
  }

  public async loadContractTemplate (contractTemplateId: string): Promise<void> {
    this.isLoading = true
    try {
      const contractTemplateResponse = await BackendConnectionManager.get<ContractTemplate>('contract-templates/' + contractTemplateId)
      this.contractTemplate = contractTemplateResponse.data
    } finally {
      this.isLoading = false
    }
  }

  public async openContract (): Promise<void> {
    if (this.currentTemplateId.trim().length === 0) {
      return
    } else if (!this.contractId || parseInt(this.contractId.toString()) <= 2022) {
      return
    }
    this.isLoading = true
    try {
      await BackendConnectionManager.openInNewTab(`v1/contracts/${this.contractId}/view-html?template=${this.currentTemplateId}`, '')
      this.contractId = 0
    } finally {
      this.isLoading = false
    }
  }
}
