
import { Options, Vue } from 'vue-class-component'
import Loading from '@/components/Loading.vue'
import Language from '@/includes/language'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import ApiResource, { Meta } from '@/interfaces/api_resource'
import Pagination from '@/components/Pagination.vue'
import { readableDateTime, setTitle, timeUntilNow } from '@/includes/functions'
import Customer from '@/interfaces/customer'
@Options({
  name: 'CustomersList',
  components: { Loading, Pagination }
})
export default class CustomersList extends Vue {
  readableDateTime = readableDateTime;
  language = Language;
  customers: Customer[] = [];
  totalCustomers = 0;
  isLoading = true;
  meta = {} as Meta;
  timeUntilNow = timeUntilNow;
  public async mounted (): Promise<void> {
    this.isLoading = false
    setTitle(this.language.lang('customers'))
    await this.loadCustomers()
  }

  public async loadCustomers (page = 1): Promise<void> {
    this.isLoading = true
    try {
      const usersResponse = await BackendConnectionManager.get<ApiResource<Customer>>('customers?page=' + page)
      this.customers = usersResponse.data.data
      this.meta = usersResponse.data.meta
      this.totalCustomers = usersResponse.data.meta.total
    } finally {
      this.isLoading = false
    }
  }
}
