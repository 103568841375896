
import { Options, Vue } from 'vue-class-component'
import Language from '@/includes/language'
import Loading from '@/components/Loading.vue'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import Toast from '@/includes/toast'
import User from '@/interfaces/user'
import store from '@/store'
import Offer from '@/interfaces/offer'
import Customer from '@/interfaces/customer'
import { parseCustomerWithTemplate, readableDateTime, setTitle } from '@/includes/functions'
import MailTemplate from '@/interfaces/mail_template'

@Options({
  name: 'View Offer',
  components: { Loading }
})
export default class ViewOffer extends Vue {
  readableDateTime = readableDateTime;
  language = Language;
  isLoading = false;
  offerInfo = {} as Offer;
  currentUser = {} as User;
  customerInfo = {} as Customer;
  parsedMailTemplate = '';
  mailTemplates: MailTemplate[] = [];
  selectedMailTemplate = 'none';

  public async mounted (): Promise<void> {
    this.isLoading = false
    const user = computed<User | null>(() => store.state.user)
    if (user.value) {
      this.currentUser = user.value
    }
    const route = useRoute()
    const offerId = computed(() => route.params.offerId)
    if (offerId.value) {
      await this.loadOfferInfo(offerId.value.toString())
      setTitle(this.language.lang('offer') + ` ${offerId.value.toString()}`)
      if (this.offerInfo.status === 'accepted') {
        await this.loadTemplateInfo()
      }
    }
  }

  public async loadOfferInfo (templateId: string): Promise<void> {
    this.isLoading = true
    try {
      const templateResponse = await BackendConnectionManager.get<Offer>('offers/' + templateId)
      this.offerInfo = templateResponse.data
      this.customerInfo = this.offerInfo.customer![0]
      this.parsedMailTemplate = parseCustomerWithTemplate(
        this.offerInfo.template?.content || '',
        this.customerInfo,
        this.currentUser.company!,
        this.offerInfo.contract!
      )
    } finally {
      this.isLoading = false
    }
  }

  public async sendAcceptConfirmation (): Promise<void> {
    this.isLoading = true
    try {
      await BackendConnectionManager.post('offers/confirm-acception', {
        templateId: this.selectedMailTemplate,
        offerId: this.offerInfo.uuid
      })
      Toast.success(this.language.lang('done'))
    } finally {
      this.isLoading = false
    }
  }

  public async loadTemplateInfo (): Promise<void> {
    this.isLoading = true
    try {
      const templateResponse = await BackendConnectionManager.get<MailTemplate[]>('mail-templates?page=offer-confirmation')
      this.mailTemplates = templateResponse.data
    } finally {
      this.isLoading = false
    }
  }

  public async deleteOffer (): Promise<void> {
    console.log('deleting mail template')
  }
}
