
import { Options, Vue } from 'vue-class-component'
import Loading from '@/components/Loading.vue'
import Language from '@/includes/language'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import ApiResource, { Meta } from '@/interfaces/api_resource'
import Pagination from '@/components/Pagination.vue'
import { readableDateTime, setTitle, timeUntilNow } from '@/includes/functions'
import Contract from '@/interfaces/contract'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
@Options({
  name: 'ContractsList',
  components: { Loading, Pagination }
})
export default class ContractsList extends Vue {
  readableDateTime = readableDateTime;
  language = Language;
  contracts: Contract[] = [];
  totalContracts = 0;
  isLoading = true;
  meta = {} as Meta;
  userId = '';
  timeUntilNow = timeUntilNow;
  public async mounted (): Promise<void> {
    this.isLoading = false
    const route = useRoute()
    const employeeId = computed(() => route.params.employeeId)
    if (employeeId.value) {
      this.userId = employeeId.value.toString()
      await this.loadContracts()
      setTitle(this.language.lang('employeeContracts'))
    }
  }

  public async loadContracts (page = 1): Promise<void> {
    this.isLoading = true
    try {
      const usersResponse = await BackendConnectionManager.get<ApiResource<Contract>>(`contracts/employee/${this.userId}?page=${page}`)
      this.contracts = usersResponse.data.data
      this.meta = usersResponse.data.meta
      this.totalContracts = usersResponse.data.meta.total
    } finally {
      this.isLoading = false
    }
  }
}
