
import { Options, Vue } from 'vue-class-component'
import Loading from '@/components/Loading.vue'
import Language from '@/includes/language'
import { useRoute } from 'vue-router'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import { computed } from 'vue'
import Customer from '@/interfaces/customer'
import User from '@/interfaces/user'
import store from '@/store'
import { UserRoles } from '@/includes/types'
import jquery from '@/includes/jquery'
import Toast from '@/includes/toast'
import Router from '@/router'
import { readableDateTime, setTitle } from '@/includes/functions'
import CreateAppointmentModal from '@/components/Customer/create_appointment_modal.vue'
import DeleteModal from '@/components/DeleteModal.vue'
import SendEmailFromTemplate from '@/components/Customer/SendEmailFromTemplate.vue'

@Options({
  name: 'ViewEmployee',
  components: {
    Loading,
    CreateAppointmentModal,
    DeleteModal,
    SendEmailFromTemplate
  }
})
export default class ViewCustomer extends Vue {
  readableDateTime = readableDateTime;
  language = Language;
  customerInfo = {} as Customer;
  isLoading = true;
  showDeleteButton = false;

  public async mounted (): Promise<void> {
    this.isLoading = false
    const currentUser = computed<User | null>(() => store.state.user)
    if (currentUser.value) {
      if (currentUser.value?.role === UserRoles.Owner) {
        this.showDeleteButton = true
      }
    }
    const route = useRoute()
    const customerId = computed(() => route.params.customerId)
    if (customerId.value) {
      await this.loadCustomer(customerId.value.toString())
      setTitle(this.language.lang('customer') + ` ${this.customerInfo.firstname} ${this.customerInfo.lastname}`)
    }
  }

  public async loadCustomer (customerId: string): Promise<void> {
    this.isLoading = true
    try {
      const customerResponse = await BackendConnectionManager.get<Customer>('customers/' + customerId)
      this.customerInfo = customerResponse.data
    } finally {
      this.isLoading = false
    }
  }

  public async deleteCustomer (): Promise<void> {
    this.isLoading = true
    try {
      await BackendConnectionManager.delete('customers/' + this.customerInfo.uuid, {})
      jquery('#deleteModal').modal('hide')
      Toast.success(this.language.lang('done'))
      await Router.push({ name: 'Customers' })
    } finally {
      this.isLoading = false
    }
  }
}
