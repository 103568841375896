
import { Options, Vue } from 'vue-class-component'
import Loading from '@/components/Loading.vue'
import Language from '@/includes/language'
import User from '@/interfaces/user'
import { useRoute } from 'vue-router'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import { setTitle, timeUntilNow } from '@/includes/functions'
import { computed } from 'vue'
import store from '@/store'
import Toast from '@/includes/toast'
import jquery from '@/includes/jquery'
import Router from '@/router'
@Options({
  name: 'ViewEmployee',
  components: { Loading }
})
export default class ViewEmployee extends Vue {
  language = Language;
  userInfo = {} as User;
  currentUser = {} as User;
  isLoading = true;
  showDeleteButton = false;
  timeUntilNow = timeUntilNow;
  public async mounted (): Promise<void> {
    this.isLoading = false
    const route = useRoute()
    const currentUser = computed<User | null>(() => store.state.user)
    if (currentUser.value) {
      this.currentUser = currentUser.value
    }
    const employeeId = computed(() => route.params.employeeId)
    if (employeeId.value) {
      if (currentUser.value?.role === 'user' && currentUser.value.uuid !== employeeId.value) {
        await Router.push({ name: 'Home' })
      }
      await this.loadUser(employeeId.value.toString())
      setTitle(this.language.lang('employee') + ` ${this.userInfo.firstname} ${this.userInfo.lastname}`)
    }
    if (this.userInfo.uuid !== this.currentUser.uuid) {
      if (this.currentUser.role === 'owner') {
        this.showDeleteButton = true
      }
    }
  }

  public async loadUser (userId: string): Promise<void> {
    this.isLoading = true
    try {
      const userResponse = await BackendConnectionManager.get<User>('users/' + userId)
      this.userInfo = userResponse.data
    } finally {
      this.isLoading = false
    }
  }

  public async deleteUser (): Promise<void> {
    this.isLoading = true
    try {
      await BackendConnectionManager.delete('users/' + this.userInfo.uuid, {})
      jquery('#deleteUserModal').modal('hide')
      Toast.success(this.language.lang('done'))
      await Router.push({ name: 'Employees' })
    } finally {
      this.isLoading = false
    }
  }
}
