
import { Options, Vue } from 'vue-class-component'
import Loading from '@/components/Loading.vue'
import Language from '@/includes/language'
import Customer from '@/interfaces/customer'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import { computed, ComputedRef, ref, watch } from 'vue'
import Jquery from '@/includes/jquery'
import Toast from '@/includes/toast'
import Router from '@/router'
import { isValidExpectedTime, parseExpectedTime, updateUserFromWhoAmiI } from '@/includes/functions'
import User from '@/interfaces/user'
import store from '@/store'
import AddressesForm from '@/components/Contracts/AddressesForm.vue'
import Address from '@/interfaces/address'
import Vehicle from '@/interfaces/vehicle'
import VehicleForm from '../Contracts/VehicleForm.vue'
import SelectCustomer from '../Contracts/SelectCustomer.vue'
import ContractItemsForm from '../ItemsForm.vue'
import { ContractItem } from '@/interfaces/contract'

@Options({
  name: 'NewContract',
  components: {
    Loading,
    AddressesForm,
    VehicleForm,
    SelectCustomer,
    ContractItemsForm
  }
})
export default class NewContract extends Vue {
  language = Language
  isLoading = false
  newContractId = 0;
  // customer information
  selectedCustomerInfo = ref<Customer>({} as Customer)

  contractHeader = '';
  contractFooter = '';

  // start relocation information
  startDateTime = new Date().toISOString().split('T')[0] + 'T08:00'
  workers = ''
  workersFood = 0.0
  vehicles: ComputedRef<number> = computed(() => 0)
  hourPrice = 0.0
  expectedTime = '0-0'
  expectedTimeParsed = {
    from: 0,
    to: 0
  }

  furnitureLift = false;
  wallMounting = false;
  lampAssemply = false;

  flatRatePrice = 0
  maxPrice = 0
  arrivalAndReturnPrice = 0
  relocationRooms = '';
  disassembly = '';
  includeMWST = true
  trashIncludeMWST = false
  priceType: 'flatRate' | 'maxPrice' | 'hourly' = 'hourly'
  transportExtraNotes = ''
  // end relocation information

  // start trash information
  trashPriceProMeter = 0
  trashFlatRatePrice = 0
  trashExtraNotes = ''
  // end trash information

  // start cleaning information
  cleaningExtraNotes = ''
  cleaningDateTime: 'open' | 'select' | 'no-cleaning' = 'no-cleaning'
  cleaningEndDateTime = new Date().toISOString().split('T')[0] + 'T08:00'
  cleaningPrice = 0.0
  cleaningRooms = 0.0
  typeOfFloor = ''
  typeOfBlinds = ''
  additionalRooms = ''
  washingTowerAvailable = false;
  fillDowelHoles = false;
  highPressureCleaning = false;
  areaForPressureCleaning = 0;
  // end cleaning information

  contractItems: ContractItem[] = []

  fromAddress: Address[] = []

  toAddress: Address[] = []
  canCreateNewContract: ComputedRef<boolean> = computed(() => false)
  canDoFirstNext: ComputedRef<boolean> = computed(() => false)
  canDoThirdNext: ComputedRef<boolean> = computed(() => false)
  canDoTrashNext: ComputedRef<boolean> = computed(() => false)
  isValidExpectedTimeValue = false;
  formStepsNumber = 1
  formSteps: ComputedRef<number> = computed(() => this.formStepsNumber)

  allVehicles: { uuid: string; count: number; name: string }[] = []

  buildingTypeAutoComplete: string[] = [];
  vehiclesAutoComplete: string[] = [];
  disassemblyAutoComplete: string[] = [];
  hidePages: string[] = [];

  public async mounted (): Promise<void> {
    this.isLoading = true
    await updateUserFromWhoAmiI()
    this.canCreateNewContract = computed<boolean>(() => {
      if (!this.canDoFirstNext || !this.canDoThirdNext || !this.canDoTrashNext) {
        return false
      } else if (this.cleaningDateTime !== 'no-cleaning') {
        if (this.cleaningExtraNotes.trim().length > 500) {
          return false
        } else if ([this.cleaningRooms.toString().trim(), this.cleaningPrice.toString().trim()].includes('')) {
          return false
        } else if (this.cleaningRooms <= 0 || this.cleaningPrice <= 0) {
          return false
        } else if (
          this.cleaningDateTime === 'select' &&
          this.cleaningEndDateTime.trim() === ''
        ) {
          return false
        }
      }
      return true
    })
    this.canDoFirstNext = computed<boolean>(() => {
      return ![this.fromAddress.length, this.toAddress.length].includes(0)
    })
    this.canDoTrashNext = computed<boolean>(() => {
      return !([this.trashFlatRatePrice.toString().trim(), this.trashPriceProMeter.toString().trim()].includes('')) &&
        this.trashPriceProMeter >= 0 && this.trashFlatRatePrice >= 0
    })
    this.canDoThirdNext = computed<boolean>(() => {
      if (this.startDateTime === '') {
        return false
      } else if (
        this.workers.toString().trim() === '' || parseInt(this.workers) <= 0 ||
        this.allVehicles.length <= 0 ||
        this.relocationRooms.toString().trim() === '' || parseFloat(this.relocationRooms) <= 0
      ) {
        return false
      } else if (this.transportExtraNotes.trim().length > 500) {
        return false
      }

      if (!isValidExpectedTime(this.expectedTime)) {
        return false
      } else if (this.priceType === 'hourly') {
        if (this.hourPrice <= 0) {
          return false
        }
      } else if (this.priceType === 'flatRate') {
        if (this.flatRatePrice <= 0) {
          return false
        }
      } else if (this.priceType === 'maxPrice') {
        if (this.maxPrice <= 0) {
          return false
        }
      }
      return true
    })
    this.vehicles = computed<number>(() => {
      let vehiclesCount = 0
      for (const vehicle of this.allVehicles) {
        vehiclesCount += vehicle.count
      }
      return vehiclesCount
    })
    this.loadAutoCompleteWords().catch()
    const formSteps = computed(() => this.formStepsNumber)
    watch<number>(formSteps, (newStep: number, oldStep: number) => {
      if (newStep === oldStep) {
        return
      }
      if (newStep > oldStep) {
        Jquery('#newContractSteps').carousel('next')
      } else {
        Jquery('#newContractSteps').carousel('prev')
      }
    })
    const watchExpectedTime = computed<string>(() => this.expectedTime)
    watch<string>(watchExpectedTime, (newValue: string) => {
      if (isValidExpectedTime(newValue)) {
        this.isValidExpectedTimeValue = true
        const times = parseExpectedTime(newValue)
        this.expectedTimeParsed.from = times[0] || 0
        this.expectedTimeParsed.to = times[1] || 0
      } else {
        this.isValidExpectedTimeValue = false
        this.expectedTimeParsed.from = 0
        this.expectedTimeParsed.to = 0
      }
    })
    const currentUser = computed<User | null>(() => store.state.user)
    if (currentUser.value) {
      this.hidePages = currentUser.value?.company?.hide_new_contract_page.split(',').map(t => t.trim()) || []
      this.newContractId = (currentUser.value?.company?.contracts || 0) + 1
      // add the company contract header to the contract header
      if (currentUser.value.company?.contract_header) {
        this.contractHeader = currentUser.value.company.contract_header
      }
      if (currentUser.value.company?.contract_footer) {
        this.contractFooter = currentUser.value.company.contract_footer
      }
    }
  }

  public nextStep (): void {
    this.cleaningRooms = parseFloat(this.relocationRooms)
    this.formStepsNumber++
    this.formSteps = computed(() => this.formStepsNumber)
    window.$(document.scrollingElement || document.body).animate(
      {
        scrollTop: 0
      },
      400
    )
  }

  public prevStep (): void {
    this.formStepsNumber--
    this.formSteps = computed(() => this.formStepsNumber)
    window.$(document.scrollingElement || document.body).animate(
      {
        scrollTop: 0
      },
      400
    )
  }

  public updateFromAddress (addresses: Address[]): void {
    this.fromAddress = addresses
  }

  public updateToAddress (addresses: Address[]): void {
    this.toAddress = addresses
  }

  public async cleanSelectedCustomer (): Promise<void> {
    this.selectedCustomerInfo.value = {} as Customer
    this.fromAddress = []
    this.toAddress = []
  }

  public async selectCustomer (customer: Customer): Promise<void> {
    this.selectedCustomerInfo.value = customer
  }

  public async createNewContract (): Promise<void> {
    this.isLoading = true
    try {
      await BackendConnectionManager.post('contracts', {
        customerId: this.selectedCustomerInfo.value?.uuid,
        isTherePackaging: false,
        // end packaging
        // start relocation
        startDateTime: this.startDateTime,
        workers: this.workers,
        priceType: this.priceType,
        flatRatePrice: this.flatRatePrice,
        hourPrice: this.hourPrice,
        expectedTime: this.expectedTime.trim() === '' ? '0-0' : this.expectedTime.trim(),
        arrivalAndReturnPrice: this.arrivalAndReturnPrice,
        includeMwst: this.includeMWST,
        relocationRooms: this.relocationRooms,
        extraNotes: this.transportExtraNotes,
        maxPrice: this.maxPrice,
        allVehicles: this.allVehicles,
        workersFood: this.workersFood,
        // end relocation
        trashIncludeMwst: this.trashIncludeMWST,
        trashExtraNotes: this.trashExtraNotes,
        trashFlatRatePrice: this.trashFlatRatePrice,
        trashPriceProM: this.trashPriceProMeter,
        cleaning: this.cleaningDateTime,
        cleaningEndDateTime: this.cleaningEndDateTime,
        cleaningRooms: this.cleaningRooms,
        cleaningPrice: this.cleaningPrice,
        cleaningExtraNotes: this.cleaningExtraNotes,
        disassembly: this.disassembly,
        items: this.contractItems,
        fromAddresses: this.fromAddress.map((ad) => {
          if (ad.building_type?.trim().length === 0) {
            ad.building_type = null
          }
          return ad
        }),
        toAddresses: this.toAddress.map((ad) => {
          if (ad.building_type?.trim().length === 0) {
            ad.building_type = null
          }
          return ad
        }),
        lampAssemply: this.lampAssemply,
        wallMounting: this.wallMounting,
        furnitureLift: this.furnitureLift,
        contractHeader: this.contractHeader,
        contractFooter: this.contractFooter,
        typeOfFloor: this.typeOfFloor,
        typeOfBlinds: this.typeOfBlinds,
        additionalRooms: this.additionalRooms,
        washingTowerAvailable: this.washingTowerAvailable,
        fillDowelHoles: this.fillDowelHoles,
        highPressureCleaning: this.highPressureCleaning,
        areaForPressureCleaning: this.areaForPressureCleaning
      })
      Toast.success(this.language.lang('contractCreatedSuccess'))
      await Router.push({ name: 'Contracts' })
    } catch (error: any) {
      console.log(error)
      // eslint-disable-next-line no-unused-expressions
      error.response?.data?.errors?.forEach((singleError: { message: string }) => {
        Toast.danger(singleError.message)
      })
    } finally {
      this.isLoading = false
    }
  }

  public async loadAutoCompleteWords (): Promise<void> {
    this.isLoading = true
    try {
      const autoComplete = await BackendConnectionManager.get<{ buildingTypes: string[], vehicles: string[], disassemblies: string[] }>('auto-complete/all')
      this.buildingTypeAutoComplete = autoComplete.data.buildingTypes
      this.vehiclesAutoComplete = autoComplete.data.vehicles
      this.disassemblyAutoComplete = autoComplete.data.disassemblies
    } finally {
      this.isLoading = false
    }
  }

  public updateVehicles (vehicles: Vehicle[]): void {
    this.allVehicles = vehicles
  }

  public updateContractItems (items: ContractItem[]): void {
    this.contractItems = items
  }
}
